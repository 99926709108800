import { useState, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBanner from "../PageBanner";

const serv = new UserService();
const ValidateSchema = Yup.object({
  newPassword: Yup.string().required("New Password is a required field"),
  verifyPassword: Yup.string().required("Verify Password is a required field"),
});
function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVeri, setShowPasswordVeri] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loginObj, setLoginObj] = new useState({
    newPassword: "",
    verifyPassword: "",
  });

  const onSubmit = async (values) => {
    if (token != "") {
      let obj = { ...values, token: token };

      try {
        const resp = await serv.resetPassword(obj);
        if (resp?.result) {
          notify(resp);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          notify(resp);
          setErrorMsg(resp.error);
        }
      } catch (err) {
        err = JSON.parse(err);
        setErrorMsg(err.err);
      }
    } else {
      toast("Invalid User!!!");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowPasswordVeri = () => {
    setShowPasswordVeri(!showPasswordVeri);
  };
  const formik = useFormik({
    initialValues: loginObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  // const styles = {
  //   main: {
  //     background: "#fff",
  //     boxShadow: "0 0 12px 0 rgb(21 17 148 / 6%)",
  //     borderRadius: 4,
  //     border: "1px solid #dbddf1",
  //     padding: 24,
  //     marginTop: 30,
  //   },
  // };

  const notify = (msg) => {
    if (msg.error) toast(`${msg.error}`);
    else toast(`${msg.message}`);
  };

  return (
    <section
      className="w-100 clearfix socialMediaTheme"
      style={{ marginBottom: 50 }}
    >
      <PageBanner props="Reset Password" />
      <div
        className="d-flex justify-content-center reset-form-card-wrapper"
        style={{ marginTop: 50 }}
      >
        <div className="loginForm_left">
          <div className="wrapper wrapper2 reset-password-card">
            <div className="loginLogo"></div>
            <div className="logInform">
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <form onSubmit={formik.handleSubmit}>
                <div className="commonform">
                  <label htmlFor="passwordLogin" className="form-label">
                    New Password
                  </label>
                  <div className="position-relative">
                    <input
                      className={
                        "form-control" +
                        (formik.touched.newPassword && formik.errors.newPassword
                          ? " is-invalid"
                          : "")
                      }
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      name="newPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                    />
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <div className="valid_feedbackMsg">
                        <p style={{ color: "red" }}>
                          {formik.errors.newPassword}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="commonform" style={{ marginTop: 30 }}>
                  <label htmlFor="passwordLogin" className="form-label">
                    Verify Password
                  </label>
                  <div className="position-relative">
                    <input
                      className={
                        "form-control" +
                        (formik.touched.verifyPassword &&
                        formik.errors.verifyPassword
                          ? " is-invalid"
                          : "")
                      }
                      type={showPasswordVeri ? "text" : "password"}
                      placeholder=""
                      name="verifyPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.verifyPassword}
                    />
                    {formik.touched.verifyPassword &&
                    formik.errors.verifyPassword ? (
                      <div className="valid_feedbackMsg">
                        <p className="user-auth-msg" style={{ color: "red" }}>
                          {formik.errors.verifyPassword}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                {errorMsg && (
                  <div className="valid_feedbackMsg text-center">
                    <p
                      className="user-auth-msg"
                      style={{ color: "red", marginTop: 20 }}
                    >
                      {errorMsg}
                    </p>
                  </div>
                )}
                <div
                  className="loginBtn"
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    class="btn ripple  btn-primary btn-block"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
            <div className="loginPara text-center" style={{ marginTop: 30 }}>
              <p style={{ marginBottom: 0 }}>
                Click here to &nbsp;
                <a
                  href="javascript:void(0)"
                  className="join"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ResetPassword;
