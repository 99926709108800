import React from "react";

export default function PageBanner({ props }) {
  return (
    <div
      className="bannerimg"
      style={{ backgroundColor: "#6b66f8", paddingTop: 150 }}
    >
      <div className="header-text mb-0">
        <div className="container">
          <div className="text-center text-white">
            <h1> {props} </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
