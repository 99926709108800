import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBanner from "../PageBanner";

const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
  password: Yup.string().required("Password is a required field"),
  first_name: Yup.string().required("First name is a required field"),
  last_name: Yup.string().required("Last name is a required field"),
  confirm_password: Yup.string().required(
    "Confirm Password is a required field"
  ),
});

function Signup() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loginObj, setLoginObj] = new useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "2", //2 means seller
  });

  const notify = (msg) => {
    if (msg.error) toast(`${msg.error}`);
    else toast(`${msg.message}`);
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    if (obj.password === obj.confirm_password) {
      delete obj.confirm_password;
      try {
        const resp = await serv.signup(obj);
        if (resp?.result) {
          localStorage.setItem("user", JSON.stringify(resp.result));
          setErrorMsg(false);
          notify(resp);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          notify(resp);
        }
      } catch (err) {
        err = JSON.parse(err.message);
      }
    } else {
      setErrorMsg(true);
      toast("Passwords does not match!");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const formik = useFormik({
    initialValues: loginObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const onChangeFormData = (val) => {
    console.log(val);
  };
  return (
    <div>
      <section class="sptb">
        <PageBanner props="Sign Up" />
        <div class="container customerpage user-auth-form-card">
          <div class="row">
            <div class="single-page">
              <div class="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div class="wrapper wrapper2">
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  <form
                    id="Register"
                    onSubmit={formik.handleSubmit}
                    class="card-body"
                    tabindex="500"
                  >
                    <div class="name">
                      <input
                        type="text"
                        name="first_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                        className={
                          "form-control" +
                          (formik.touched.first_name && formik.errors.first_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label>First name</label>
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="valid_feedbackMsg">
                          <p
                            className="user-auth-msg"
                            style={{
                              color: "red",
                              marginTop: 10,
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.first_name}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div class="name">
                      <input
                        type="text"
                        name="last_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                        className={
                          "form-control" +
                          (formik.touched.last_name && formik.errors.last_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label>Last name</label>
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="valid_feedbackMsg">
                          <p
                            className="user-auth-msg"
                            style={{
                              color: "red",
                              marginTop: 10,
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.last_name}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div class="mail">
                      <input
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className={
                          "form-control" +
                          (formik.touched.email && formik.errors.email
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label>E-Mail</label>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="valid_feedbackMsg">
                          <p
                            className="user-auth-msg"
                            style={{
                              color: "red",
                              marginTop: 10,
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.email}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div class="passwd">
                      <input
                        type="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className={
                          "form-control" +
                          (formik.touched.password && formik.errors.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label>Password</label>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="valid_feedbackMsg">
                          <p
                            className="user-auth-msg"
                            style={{
                              color: "red",
                              marginTop: 10,
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.password}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="passwd">
                      <input
                        className={
                          "form-control" +
                          (formik.touched.confirm_password &&
                          formik.errors.confirm_password
                            ? " is-invalid"
                            : "")
                        }
                        type="password"
                        placeholder=""
                        name="confirm_password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirm_password}
                      />
                      <label> Confirm Password</label>
                      {formik.touched.confirm_password &&
                      formik.errors.confirm_password ? (
                        <div className="valid_feedbackMsg">
                          <p className="user-auth-msg" style={{ color: "red" }}>
                            {formik.errors.confirm_password}
                          </p>
                        </div>
                      ) : null}
                    </div>

                    {errorMsg && (
                      <div className="valid_feedbackMsg text-center">
                        <p
                          className="user-auth-msg"
                          style={{ color: "red", marginTop: 20 }}
                        >
                          Password does not match!
                        </p>
                      </div>
                    )}

                    <div class="submit">
                      <button
                        class="btn ripple  btn-primary btn-block"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <p class="text-dark mb-0">
                      Already have an account?
                      <a class="text-primary ms-1">
                        <Link to={"/login"}>Sign In</Link>
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  //   return (
  //     <main className="w-100 clearfix socialMediaTheme">
  //       <div className="main_container position-relative">
  //         <div className="signUplogo">
  //           <div className="container">
  //             <a
  //               href="javascript:void(0);"
  //               alt="logo"
  //               className="img-fluid signUpLogo"
  //             >
  //               <img src="/images/profile/logo-white.png" className="img-fluid" />
  //             </a>
  //           </div>
  //         </div>
  //         <div className="sigUpSection">
  //           <div className="signUphead text-center">
  //             <h3>Getting Started</h3>
  //             <p className="pb-2 pb-sm-4">Create an account to continue.</p>
  //           </div>
  //           <div className="signUpform">
  //             <div className="signUpWith_google text-center">
  //               <a href="javascript:void(0)" className="goggleBtn_link">
  //                 <img src="/images/profile/google.svg" />
  //                 Sign up with Google
  //               </a>
  //             </div>
  //             <div className="notA_member OR_text d-flex align-items-center">
  //               <span />
  //               <h5>OR</h5>
  //               <span />
  //             </div>
  //             <div className="signUpInnerForm">
  //               <form onSubmit={formik.handleSubmit}>
  //                 <div className="mb-3 mb-sm-4 commonform">
  //                   <label htmlFor="username" className="form-label">
  //                     Username*
  //                   </label>
  //                   <input
  //                     className={
  //                       "form-control" +
  //                       (formik.touched.user_name && formik.errors.user_name
  //                         ? " is-invalid"
  //                         : "")
  //                     }
  //                     placeholder="Michel42"
  //                     name="user_name"
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.user_name}
  //                   />
  //                   {formik.touched.user_name && formik.errors.user_name ? (
  //                     <div className="valid_feedbackMsg">
  //                       {formik.errors.user_name}
  //                     </div>
  //                   ) : null}
  //                 </div>
  //                 <div className="mb-3 mb-sm-4 commonform">
  //                   <label htmlFor="userEmail" className="form-label">
  //                     Email*
  //                   </label>
  //                   <input
  //                     className={
  //                       "form-control" +
  //                       (formik.touched.email && formik.errors.email
  //                         ? " is-invalid"
  //                         : "")
  //                     }
  //                     placeholder="john@mavefi.com"
  //                     name="email"
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     value={formik.values.email}
  //                   />
  //                   {formik.touched.email && formik.errors.email ? (
  //                     <div className="valid_feedbackMsg">
  //                       {formik.errors.email}
  //                     </div>
  //                   ) : null}
  //                 </div>
  //                 <div className="commonform">
  //                   <label htmlFor="passwordLogin" className="form-label">
  //                     Create Password*
  //                   </label>
  //                   <div className="position-relative">
  //                     <input
  //                       className={
  //                         "form-control" +
  //                         (formik.touched.password && formik.errors.password
  //                           ? " is-invalid"
  //                           : "")
  //                       }
  //                       type={showPassword ? "text" : "password"}
  //                       placeholder=""
  //                       name="password"
  //                       onChange={formik.handleChange}
  //                       onBlur={formik.handleBlur}
  //                       value={formik.values.password}
  //                     />
  //                     {formik.touched.password && formik.errors.password ? (
  //                       <div className="valid_feedbackMsg">
  //                         {formik.errors.password}
  //                       </div>
  //                     ) : null}
  //                     <span className="showHidetoggle">
  //                       {!formik.errors.password ? (
  //                         showPassword ? (
  //                           <img
  //                             src="/images/profile/show_pass.svg"
  //                             className="showLoginPass"
  //                             onClick={handleShowPassword}
  //                           />
  //                         ) : (
  //                           <img
  //                             src="/images/profile/Hide-pass.svg"
  //                             className="showLoginPass"
  //                             onClick={handleShowPassword}
  //                           />
  //                         )
  //                       ) : (
  //                         ""
  //                       )}
  //                     </span>
  //                   </div>
  //                 </div>
  //                 {errorMsg && (
  //                   <div className="valid_feedbackMsg text-center">
  //                     {errorMsg}
  //                   </div>
  //                 )}
  //                 <div className="loginBtn">
  //                   <button
  //                     type="submit"
  //                     href="javascript:void(0);"
  //                     className="btn btnColor w-100"
  //                   >
  //                     Create Account
  //                   </button>
  //                 </div>
  //               </form>
  //             </div>
  //           </div>
  //           <div className="signInLink">
  //             <p>
  //               Already have account ?
  //               <a
  //                 href="javascript:void(0)"
  //                 className="ms-2"
  //                 onClick={() => navigate("/login")}
  //               >
  //                 Sign In
  //               </a>
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </main>
  //   );
}
export default Signup;
