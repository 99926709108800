import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import util from "../../util/util";
import OfferService from "../../services/OfferService";
import { ToastContainer, toast } from "react-toastify";
import PageBanner from "../PageBanner";
import SideBar from "../seller/SideBar";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const offerServ = new OfferService();

const offerStatus = [
  { value: "", label: "Offers status" },
  { value: "revised", label: "Revised" },
  { value: "closed", label: "Closed" },
  { value: "rejected", label: "Rejected" },
  { value: "approved", label: "Approved" },
  { value: "not-approved", label: "Not approved" },
  { value: "shipped", label: "Shipped" },
  { value: "delivered", label: "Delivered" },
  { value: "draft", label: "Draft" },
  { value: "sent", label: "Sent" },
  { value: "cancelled", label: "Cancelled" },
  { value: "delivered-with-defects", label: "Delivered With Defects" },
];

export default function NewOfferList() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [offers, setOffers] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [openAction, setOpenAction] = useState({
    open: false,
    index: false,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [showmodal, HideModal] = useState(false);
  const [showRemarks, setShowRemarks] = useState({ open: false, remarks: "" });
  const [Singleoffers, setSingleOffers] = useState([]);
  const searchState = {
    start: 0,
    length: 10,
    filter: { offer_status: "", payment_status: "", searchField: "", from_date: "", to_date: "" },
    sortBy: { createdAt: "desc" },
  };
  if (searchParams.get("s")) {
    let s = searchParams.get("s");
    if (s.includes("||")) {
      s = s.split("||");
      searchState.filter.offer_status = s[1];
      searchState.filter.payment_status = s[0];
    } else {
      searchState.filter.offer_status = searchParams.get("s");
    }
  }
  if (searchParams.get("d")) {
    if (searchParams.get("d") == "this_year") {
      searchState.filter.days = moment().dayOfYear();
    } else {
      searchState.filter.days = parseInt(searchParams.get("d"));
    }
  }
  if (searchParams.get("sd")) {
    searchState.filter.from_date = moment(searchParams.get("sd")).format("YYYY-MM-DD");
  }
  if (searchParams.get("ed")) {
    searchState.filter.to_date = moment(searchParams.get("ed")).format("YYYY-MM-DD");
  }
  if (searchParams.get("u") && searchParams.get("u") != "undefined") {
    searchState.filter.user_id = parseInt(searchParams.get("u"));
  }
  const [search, setSearch] = useState(searchState);

  function togglemodal() {
    HideModal(!showmodal);
    downloadPdf(Singleoffers);
  }

  function togglemodalClose() {
    HideModal(!showmodal);
  }

  function togglemodalshow(key) {
    HideModal(!showmodal);
    setSingleOffers(key);
  }

  async function changePaymentInfo(e) {
    let tempSearch = { ...search };
    tempSearch.filter.payment_status = e.target.value;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  async function changeOfferStatus(e) {
    let tempSearch = { ...search };
    tempSearch.filter.offer_status = e.target.value;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  function handleStartDateChange(date) {
    let tempSearch = { ...search };
    tempSearch.filter.from_date = date;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  function handleEndDateChange(date) {
    let tempSearch = { ...search };
    tempSearch.filter.to_date = date;
    tempSearch.start = 0;
    setSearch(tempSearch);
  }

  const handlePaging = (pageToView) => {
    let tempSearch = { ...search };
    tempSearch.start = parseInt(pageToView) * search.length - search.length;
    setSearch(tempSearch);
  };

  let active = Math.ceil((search.start + 1) / search.length);
  let pages = Math.ceil(totalCount / search.length);

  useEffect(() => {
    fetchOfferList();
  }, [search]);
  const fetchOfferList = async () => {
    try {
      let searchObj = JSON.parse(JSON.stringify(search));
      searchObj.filter.from_date = searchObj.filter.from_date
        ? util.formatDateAsREACT_APP_MOMENT_TIME_ZONE(search.filter.from_date) + " 00:00:00"
        : "";
      searchObj.filter.to_date = searchObj.filter.to_date
        ? util.formatDateAsREACT_APP_MOMENT_TIME_ZONE(search.filter.to_date) + " 23:59:59"
        : "";
      const response = await offerServ.list(searchObj);
      if (response.data) {
        setContentLoaded(true);
        for (let i = 0; i < response.data.length; i++) {
          let offer_status = response.data[i].offer_status;
          if (offer_status === "closed" || offer_status === "rejected") {
            response.data[i].className = "badge bg-danger";
          } else if (offer_status === "shipped" || offer_status === "approved") {
            response.data[i].className = "badge bg-success";
          } else if (offer_status === "revised") {
            response.data[i].className = "badge bg-info";
          } else {
            response.data[i].className = "badge bg-primary";
          }
          response.data[i].createdAt = moment(response.data[i].createdAt)
            .tz(process.env.REACT_APP_MOMENT_TIME_ZONE)
            .format("MM-DD-YYYY");
        }
        setOffers(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  };

  const CloneOffer = async (key) => {
    try {
      if (window.confirm(`Do you want to clone this Offer #${key.id} ?`)) {
        let response = await offerServ.clone(key.id);
        if (response.message) {
          fetchOfferList();
          toast.success(response.message);
          // setTotalCount(totalCount - 1);
        } else {
          toast.error("Offer not cloned");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteOffers = async (key) => {
    try {
      if (window.confirm(`Are you sure you want to delete this Offer #${key.id} ?`)) {
        let response = await offerServ.delete(key.id);
        if (response.message) {
          fetchOfferList();
          toast.success(response.message);
          // setTotalCount(totalCount - 1);
        } else {
          toast.error("Offer not deleted ");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  function ResetValue() {
    let tempSearch = { ...search };
    tempSearch.filter.payment_status = "";
    tempSearch.filter.offer_status = "";
    tempSearch.filter.from_date = "";
    tempSearch.filter.to_date = "";
    tempSearch.filter.days = "";
    setSearch(tempSearch);
  }

  const addEditOffer = (key) => {
    navigate("/offer/" + key.id);
  };
  const previewOffer = (key) => {
    navigate("/offer/preview/" + key.id);
  };
  const downloadPdf = async (key) => {
    try {
      let response = await offerServ.GetPdf(key.id);
      window.open(response.data);
    } catch (err) {
      throw err;
    }
  };
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  const handleRemarks = (index) => {
    let remarks = "";
    for (let i = 0; i < offers.length; i++) {
      if (i == index) {
        remarks = offers[i].customerRemarks;
        break;
      }
    }
    setShowRemarks((prev) => {
      return {
        open: true,
        remarks,
      };
    });
  };
  return (
    <div
      onClick={() => {
        if (openAction.open) {
          setOpenAction({ open: false, index: openAction.index });
        }
      }}
    >
      {!contentLoaded ? (
        <div className="loadingImg">
          <img src="/assets/images/loader.svg" className="text-center" />
        </div>
      ) : (
        <div>
          {showmodal == true && (
            <>
              <div className="modal d-block mt-5 pt-5" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Instructions</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={togglemodalClose}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>Your offer has been approved. Please take pdf.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary" onClick={togglemodal}>
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop show"></div>
            </>
          )}
          {showRemarks.open == true && (
            <>
              <div className="modal d-block mt-5 pt-5" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Remarks</h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setShowRemarks((prev) => {
                            return {
                              open: false,
                              remarks: "",
                            };
                          });
                        }}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">{showRemarks.remarks}</div>
                  </div>
                </div>
              </div>
              <div className="modal-backdrop show"></div>
            </>
          )}
          <section>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <PageBanner props="Offers List" />
          </section>
          <section className="sptb">
            <div className="container">
              <div className="row">
                <SideBar />
                <div className="col-xl-9 col-lg-12 col-md-12">
                  <div className="card mb-0">
                    <div className="card-header ">
                      <h3 className="card-title">Offers List</h3>
                    </div>
                    <div className="table-responsive statement-card">
                      <table className="table card-table table-vcenter text-nowrap">
                        <tbody>
                          <tr>
                            <th className="mobile-col-to-row">
                              <DatePicker
                                selected={search.filter.from_date}
                                placeholderText="Start Date"
                                onChange={(date) => handleStartDateChange(date)}
                                maxDate={search.filter.to_date}
                              />
                            </th>
                            <th className="mobile-col-to-row">
                              <DatePicker
                                selected={search.filter.to_date}
                                placeholderText="End Date"
                                onChange={(date) => handleEndDateChange(date)}
                                minDate={search.filter.from_date}
                              />
                            </th>
                            <th className="mobile-col-to-row">
                              <select
                                onChange={(e) => changeOfferStatus(e)}
                                className="input-group p-1"
                                value={search.filter.offer_status}
                              >
                                {offerStatus.map((st) => {
                                  return <option value={st.value}>{st.label} </option>;
                                })}
                              </select>
                            </th>
                            <th className="mobile-col-to-row">
                              <select
                                onChange={(e) => changePaymentInfo(e)}
                                className="p-1 input-group"
                                value={search.filter.payment_status}
                              >
                                <option className="p-1" value="">
                                  Payment status
                                </option>
                                <option className="p-1" value="paid">
                                  Paid
                                </option>
                                <option className="p-1" value="unpaid">
                                  Unpaid
                                </option>
                              </select>
                            </th>
                            <th className="mobile-col-to-row">
                              <button className="btn ripple w-100  btn-sm btn-secondary " onClick={() => ResetValue()}>
                                Reset
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive statement-card">
                        <table className="table card-table table-vcenter text-nowrap border-bottom">
                          <thead>
                            <tr>
                              <th>Offer ID</th>
                              <th>Remarks</th>
                              <th>Created At</th>
                              <th>Offer Status</th>
                              <th>Total Amount</th>
                              <th>
                                Payment
                                <br />
                                STATUS
                              </th>
                              <th>
                                <div className="hands-icon">
                                  <span className="fa fa-comment p-1 me-1"></span>
                                </div>
                              </th>
                              <th className="position-relative">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {offers.map((key, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Link
                                      className="text-primary"
                                      to={key.offer_status == "draft" ? `/offer/${key.id}` : `/offer/preview/${key.id}`}
                                    >
                                      {key.id ? key.id : "NF"}
                                    </Link>
                                  </td>
                                  <td className="text-center hands-icon">
                                    {offers[index].customerRemarks && (
                                      <i
                                        className="fa fa-sticky-note-o"
                                        aria-hidden="true"
                                        onClick={() => {
                                          handleRemarks(index);
                                        }}
                                      ></i>
                                    )}
                                  </td>
                                  <td className="fs-16">{key.createdAt ? key.createdAt : "NF"}</td>
                                  <td className="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      className={key?.className ? key.className : "badge bg-info"}
                                    >
                                      {key.offer_status ? key.offer_status.toUpperCase().replace("-", " ") : "NF"}
                                    </a>
                                  </td>
                                  <td className="font-weight-semibold fs-18 text-center">
                                    {key.total_price ? key.total_price : "NF"}
                                  </td>

                                  <td className="text-center">
                                    <a
                                      href="javascript:void(0);"
                                      className={key?.payment_status === "paid" ? "badge bg-primary" : "badge bg-red"}
                                    >
                                      {key.payment_status ? key.payment_status.toUpperCase() : "NF"}
                                    </a>
                                  </td>
                                  <td>
                                    <Link className="text-primary" to={"/message/" + key.id}>
                                      <span className="fa fa-comment p-1 me-1 chaticon">
                                        {key.readCount ? <span>{key.readCount}</span> : ""}
                                      </span>
                                    </Link>
                                  </td>
                                  <td style={{ position: "relative" }}>
                                    <i
                                      className="fa fa-ellipsis-v p-2 hands-icon"
                                      aria-hidden="true"
                                      onClick={() =>
                                        setOpenAction((prev) => {
                                          return {
                                            open: !prev.open,
                                            index: index,
                                            count: 4,
                                          };
                                        })
                                      }
                                    ></i>
                                    {openAction.open && openAction.index == index && (
                                      <div className="border rounded bg-white z-10 position-absolute top-10  end-30">
                                        <ul className="list-group ">
                                          {/* <a
                                  className="fa fa-trash action-icons delete-btn"
                                  onClick={() => deleteOffers(key)}
                                ></a> */}
                                          {key.offer_status === "draft" ? (
                                            <li
                                              className="mx-3 my-1 mt-2 border-0 d-flex jusity-content-around pe-auto hands-icon"
                                              onClick={() => {
                                                deleteOffers(key);
                                              }}
                                            >
                                              <span className="fa fa-trash action-icons delete-btn me-1"></span>

                                              <span className="text-primary fw-light ms-3">Delete Offer</span>
                                            </li>
                                          ) : null}
                                          {/* <a
                                  className={
                                    key.offer_status === "approved" || key.offer_status == "shipped" || key.offer_status == "rejected" || key.offer_status == "closed"
                                      ? null
                                      : "fa fa-edit action-icons edit-btn"
                                  }
                                  onClick={() => addEditOffer(key)}
                                ></a> */}
                                          {key.offer_status === "draft" || key.offer_status == "sent" ? (
                                            <li
                                              className="mx-3 my-1 border-0 mt-0 d-flex jusity-content-around hands-icon"
                                              onClick={() => {
                                                addEditOffer(key);
                                              }}
                                            >
                                              <span className={"fa fa-edit action-icons edit-btn"}></span>

                                              <span className="text-primary fw-light ms-3"> Edit Offer</span>
                                            </li>
                                          ) : null}

                                          {/* <a
                                  className="fa fa-eye action-icons eye-btn"
                                  onClick={() => previewOffer(key)}
                                ></a> */}
                                          <li
                                            className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                            onClick={() => previewOffer(key)}
                                          >
                                            <span className="fa me-1  fa-eye action-icons eye-btn"></span>

                                            <span className="text-primary fw-light ms-3">Preview Offer</span>
                                          </li>
                                          {/* <a
                                          className={
                                            key.offer_status == "approved"
                                              ? null
                                              : "fa fa-file-pdf-o action-icons"
                                          }
                                          onClick={() => togglemodalshow(key)}
                                        ></a> */}

                                          {key.offer_status != "approved" ? null : (
                                            <li
                                              className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                              onClick={() => togglemodalshow(key)}
                                            >
                                              <span
                                                className="fa fa-file-pdf-o action-icons"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                              ></span>

                                              <span className="text-primary fw-light ms-3 me-1"> Download Pdf</span>
                                            </li>
                                          )}
                                          {/* <a
                                          class="fa fa-clone action-icons"
                                          onClick={() => CloneOffer(key)}
                                        ></a> */}
                                          <li
                                            className="mx-3 my-1 border-0 m-0 d-flex jusity-content-around hands-icon"
                                            onClick={() => CloneOffer(key)}
                                          >
                                            <span className="fa fa-clone action-icons me-1 fa-solid "></span>

                                            <span className="text-primary fw-light ms-3">Clone Offer</span>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {pages > 1 && (
                        <ul className="pagination d-flex justify-content-center mt-2">
                          {active > 1 && (
                            <li className="page-item page-prev">
                              <a
                                className="page-link PgPd"
                                href="javascript:void(0);"
                                onClick={() => handlePaging(active - 1)}
                              >
                                Prev
                              </a>
                            </li>
                          )}
                          {[...Array(pages).keys()].map((i) => (
                            <li className={"page-item" + (active == i + 1)}>
                              <a
                                className="page-link PgPd"
                                href="javascript:void(0);"
                                onClick={() => handlePaging(i + 1)}
                              >
                                {i + 1}
                              </a>
                            </li>
                          ))}

                          {active < pages && (
                            <li className="page-item page-next">
                              <a
                                className="page-link PgPd"
                                href="javascript:void(0);"
                                onClick={() => handlePaging(active + 1)}
                              >
                                Next
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
