import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFormik, Formik } from "formik";
import download from "downloadjs";
import { toast, ToastContainer } from "react-toastify";
import GlobalContext from "../../context/GlobalContext";
import OfferService from "../../services/OfferService";
import PaymentService from "../../services/PaymentService";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import PageBanner from "../PageBanner";
import SideBar from "../seller/SideBar";

const service = new OfferService();
const paymentServ = new PaymentService();

function Offers() {
  const globalCtx = useContext(GlobalContext);
  const { id } = useParams();
  const [user, setUser] = globalCtx.user;
  const navigate = useNavigate();
  const [otherExpense, setOtherExpenses] = useState({
    main: false,
  });
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offerDetails, setOfferDetails] = useState({
    user_id: user.id,
    customerRemarks: "",
    images: [],
    new: "1",
    expense_field: "",
    expense_value: "",
    payment_method_id: 0,
    remarks: "",
    tracking_num: "",
    shipment_company: "",
    tracking_url: "",
    offers_products: [
      {
        product_name: "",
        brand: "",
        upc: "",
        model_num: "",
        quantity: "",
        price: "",
        description: "",
      },
    ],
  });

  const [totalValues, setTotalValues] = useState(0);
  const [btnname, setBtnName] = useState(null);
  const [approvedId, setapprovedId] = useState(true);
  useEffect(() => {
    if (id) {
      fetchOfferDetails(id);
    }
  }, []);
  useEffect(() => {
    fetchPaymentDetails();
  }, []);
  const fetchPaymentDetails = async () => {
    const pmDetails = await paymentServ.getPaymentinfo(user.id);
    setPaymentDetails((prev) => pmDetails.data);
  };
  const fetchOfferDetails = async (id) => {
    setIsLoading(true);
    try {
      const response = await service.getOfferInfo(id);
      if (response) {
        if (
          response.offer.offer_status === "approved" ||
          response.offer.offer_status == "shipped" ||
          response.offer.offer_status == "rejected" ||
          response.offer.offer_status == "closed"
        ) {
          setapprovedId(false);
        } else {
          if (response.offer.images) {
            response.offer.images = response.offer.images.split(",");
          } else {
            response.offer.images = [];
          }
          if (response.offer.images_after_delivery) {
            response.offer.images_after_delivery = response.offer.images_after_delivery.split(",");
          } else {
            response.offer.images_after_delivery = [];
          }
          setapprovedId(true);
          setOfferDetails(response.offer);
        }
      }
    } catch (err) {}
    setIsLoading(false);
  };
  const onSubmit = async (values) => {
    let obj = { ...values };
    let formObj = new FormData();
    Object.keys(obj).forEach((k) => {
      if (k == "offers_products") {
        formObj.append("offers_products", JSON.stringify(obj.offers_products));
      } else if (k == "images") {
        for (let x = 0; x < obj.images.length; x++) {
          if (typeof obj.images[x] != "string") {
            formObj.append("images", obj.images[x]);
          } else {
            formObj.append("images", obj.images[x]);
          }
        }
      } else {
        if (k !== "total_price") {
          formObj.append(k, obj[k]);
        }
      }
    });
    let response = null;
    formObj.append("total_price", totalValues);
    if (btnname == "draft") {
      formObj.set("offer_status", "draft");
    } else if (btnname == "send") {
      formObj.set("offer_status", "sent");
    }
    if (!id) {
      formObj.append("payment_status", "unpaid");
    }
    if (obj.new) {
      delete obj.new;
      const OFfersProducts = formObj.get("offers_products");
      const Tp = formObj.get("total_price");
      const Ps = formObj.get("payment_status");
      const OS = formObj.get("offer_status");
      let offerId = id;
      const data = {
        products: OFfersProducts,
        TotalPrice: Tp,
        PaymentStatus: Ps,
        OfferStatus: OS,
        OfferId: offerId,
        add: true,
        edit: false,
      };
      const emailresult = await service.sendEmail({
        data: JSON.stringify(data),
      });
      response = await service.add(formObj);
    } else {
      const OFfersProducts = formObj.get("offers_products");
      const Tp = formObj.get("total_price");
      const Ps = formObj.get("payment_status");
      const OS = formObj.get("offer_status");
      let offerId = id;
      const data = {
        products: OFfersProducts,
        TotalPrice: Tp,
        PaymentStatus: Ps,
        OfferStatus: OS,
        OfferId: offerId,
        edit: true,
        add: false,
      };
      const emailresult = await service.sendEmail({
        data: JSON.stringify(data),
      });
      response = await service.edit(formObj);
    }

    if (response.err) {
      toast.error(response.message);
    } else {
      toast.success(response.message);
      setTimeout(() => {
        navigate("/offerlist");
      }, 1000);
    }
  };

  const addProduct = () => {
    let products = [...formik.values.offers_products];
    products.push({
      product_name: "",
      brand: "",
      upc: "",
      model_num: "",
      quantity: "",
      price: "",
      description: "",
    });
    // formik.setValues({ offers_products: products });
    formik.setValues({ ...formik.values, offers_products: products });
  };
  const removeProducts = (index) => {
    const rows = [...formik.values.offers_products];
    rows.splice(index, 1);
    // formik.setValues({ offers_products: rows });
    formik.setValues({ ...formik.values, offers_products: rows });
  };
  const downloadHandler = async (filePath) => {
    try {
      // download(filePath);
      window.open(filePath, "_blank");
    } catch (err) {
      return false;
    }
  };

  const ValidateSchema = Yup.object({
    offers_products: Yup.array().of(
      Yup.object().shape(
        {
          product_name: Yup.string().required("Required"),
          upc: Yup.number()
            .integer()
            .test((digits) => new String(digits).length <= 14)
            .required("Required"),
          quantity: Yup.number().integer("shouldOnlyNumber").required("Required"),
          price: Yup.number().test(
            "maxDigitsAfterDecimal",
            "Number field must have 2 digits after decimal or less",
            (number) => Number.isInteger(number * 10 ** 2)
          ),
        },
        "Offers products in not valid"
      )
    ),
    payment_method_id: Yup.number()
      .integer()
      .test("isSend", "To send an offer, you must add a payment method", function (value) {
        return btnname == "draft" || btnname == null || (btnname == "send" && !!value);
      }),
  });
  const formik = useFormik({
    initialValues: offerDetails,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  useEffect(() => {
    if (formik.values.offers_products.length == 0) {
      let expVal = Number(formik.values.expense_value);
      let totalval = 0;
      if (!isNaN(expVal)) {
        totalval += expVal;
      }
      setTotalValues((prev) => totalval.toFixed(2));
    } else {
      let TotalOfferValues = 0;
      formik.values.offers_products &&
        formik.values.offers_products.forEach((data) => {
          if (data.price == "" && data.quantity == "") {
            TotalOfferValues += 0;
          } else {
            TotalOfferValues += Number(data.price) * Number(data.quantity);
          }
        });

      let expVal = Number(formik.values.expense_value);

      if (!isNaN(expVal)) {
        TotalOfferValues += expVal;
      }
      setTotalValues((prev) => TotalOfferValues.toFixed(2));
    }
  }, [formik.values]);

  if (isLoading) {
    return (
      <div className="loadingImg">
        <img src="/assets/images/loader.svg" />
      </div>
    );
  }
  return (
    <div>
      {approvedId ? (
        <div>
          <section>
            <ToastContainer />
            <PageBanner props="My Dashboard" />
          </section>
          <section className="sptb mx-0">
            <div className="container">
              <div className="row ">
                {/* <SideBar /> */}
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card card-body">
                    <div className="d-flex justify-content-between">
                      {id && (
                        <>
                          <h5 className="border">#INV -{id}</h5>
                          <Link to="/offerlist">
                            <button className="btn ripple  btn-sm btn-secondary ">Back</button>
                          </Link>
                        </>
                      )}
                    </div>
                    <div className="d-flex ">
                      <h5 className="d-flex flex-column justify-content-center  w-95 ms-5" style={{ fontSize: "40px" }}>
                        <div className="text-center">{id !== undefined ? "Edit Offer" : "Create Offer"}</div>
                        <div className="d-flex justify-content-center">
                          <p className="fs-5 fw-bold border p-2" style={{ backgroundColor: "lightblue" }}>
                            We kindly request that all products must be brand new, factory sealed and authentic
                            condition
                          </p>
                        </div>
                      </h5>
                      {!id && (
                        <div className="d-flex flex-row-reverse w-5 ">
                          <Link to="/offerlist">
                            <button className="btn ripple  btn-sm btn-secondary ">Back</button>
                          </Link>
                        </div>
                      )}
                    </div>

                    <Formik>
                      <form initialValues={offerDetails} onSubmit={formik.handleSubmit}>
                        <div style={{ margin: "1rem 0rem 0rem 0rem" }}>
                          {formik.values.offers_products.map((data, index) => (
                            <div>
                              <div className="row" key={index}>
                                <div className="col-sm-6 col-md-4 ">
                                  <div className="form-group">
                                    <label className="form-label">Product name*</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Product name"
                                      value={formik.values.offers_products[index].product_name}
                                      name={`offers_products[${index}].product_name`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                  {formik.touched?.offers_products &&
                                  formik.touched?.offers_products[index] &&
                                  formik.touched?.offers_products[index]?.product_name &&
                                  formik.errors.offers_products &&
                                  formik.errors?.offers_products[index]?.product_name
                                    ? dispErrorMsg("Required")
                                    : null}
                                </div>
                                <div className="col-sm-6 col-md-1 ">
                                  <div className="form-group">
                                    <label className="form-label">Brand</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Brand"
                                      value={formik.values.offers_products[index].brand}
                                      name={`offers_products[${index}].brand`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-1 ">
                                  <div className="form-group w-100">
                                    <label className="form-label">Model</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Model Number"
                                      value={formik.values.offers_products[index].model_num}
                                      name={`offers_products[${index}].model_num`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-2 ">
                                  <div className="form-group">
                                    <label className="form-label">UPC*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="UPC"
                                      value={formik.values.offers_products[index].upc}
                                      name={`offers_products[${index}].upc`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched?.offers_products &&
                                    formik.touched?.offers_products[index] &&
                                    formik.touched?.offers_products[index]?.upc &&
                                    formik.errors.offers_products &&
                                    formik.errors?.offers_products[index]?.upc
                                      ? dispErrorMsg("Enter digits with max length of 14")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-1 ">
                                  <div className="form-group">
                                    <label className="form-label">Quantity*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Quantity"
                                      value={formik.values.offers_products[index].quantity}
                                      name={`offers_products[${index}].quantity`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched?.offers_products &&
                                    formik.touched?.offers_products[index] &&
                                    formik.touched?.offers_products[index]?.quantity &&
                                    formik.errors.offers_products &&
                                    formik.errors?.offers_products[index]?.quantity
                                      ? dispErrorMsg("Required and enter only number")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-1 ">
                                  <div className="form-group">
                                    <label className="form-label">Price*</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Price"
                                      value={formik.values.offers_products[index].price}
                                      name={`offers_products[${index}].price`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.offers_products &&
                                    formik.touched.offers_products[index]?.price &&
                                    formik.errors.offers_products &&
                                    formik.errors?.offers_products[index]?.price
                                      ? dispErrorMsg("Price must have 2 digits after decimal or less")
                                      : null}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-1 ">
                                  <div className="form-group">
                                    <label className="form-label">Sub total</label>
                                    <h3 className="text-center mt-3">
                                      {(
                                        formik.values.offers_products[index].price *
                                        formik.values.offers_products[index].quantity
                                      ).toFixed(2)}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col pt-4 col-md-1 ">
                                  <button
                                    type="button"
                                    className="btn btn-secondary remove-items-btn"
                                    onClick={() => removeProducts(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-12">
                                  <div className="form-group form-group-margin-bottom">
                                    <label className="form-label">Description</label>
                                    <textarea
                                      type="text"
                                      placeholder="Details"
                                      name={`offers_products[${index}].description`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.offers_products[index].description}
                                      className={"form-control"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                          <button
                            type="button"
                            className="btn btn-secondary add-more-btn"
                            onClick={() => {
                              addProduct();
                            }}
                          >
                            Add product
                          </button>
                        </div>
                        <div className="row my-3">
                          <div className="col-4">
                            <label className="form-label">Select payout method</label>
                            <select
                              type="text"
                              className="form-control"
                              value={formik.values.payment_method_id}
                              name="payment_method_id"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option className="p-2 fs-5 " value="0">
                                Select payout method
                              </option>
                              {Array.isArray(paymentDetails) && paymentDetails.length > 0 ? (
                                <>
                                  {paymentDetails.map((detail) => {
                                    return (
                                      <option className="p-2 fs-5 " key={detail.platform} value={detail.id}>
                                        {detail.platform}({detail.details})
                                      </option>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <option value="">Select Payout Method</option>
                                </>
                              )}
                            </select>
                            {formik.touched.payment_method_id && formik.errors.payment_method_id
                              ? dispErrorMsg(
                                  "Please select a payout method or update preferences on your profile page if none available."
                                )
                              : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group form-group-margin-bottom">
                              <label className="form-label">Customer remarks</label>
                              <textarea
                                type="text"
                                placeholder="Details"
                                name="customerRemarks"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.customerRemarks}
                                className={
                                  "form-control" +
                                  (formik.touched.customerRemarks && formik.errors.customerRemarks ? " is-invalid" : "")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="form-group form-group-margin-bottom">
                              <label className="form-label">Images</label>
                              {formik.values.images && formik.values.images.length > 0 ? (
                                Object.values(formik.values.images).map((im, index) => {
                                  if (typeof im != "string") {
                                    return (
                                      <>
                                        <img
                                          src={URL.createObjectURL(im)}
                                          className="imgPreview"
                                          onClick={(e) => {
                                            downloadHandler(e.target.src);
                                          }}
                                        />
                                        <i
                                          onClick={(e) => {
                                            let text = "want to remove image";
                                            if (window.confirm(text) == true) {
                                              formik.values.images = formik.values.images.filter(
                                                (obj) => obj !== formik.values.images[index]
                                              ); // create a new array without the object with id = 2
                                              let array = [...formik.values.images];
                                              formik.setFieldValue("images", array);
                                            } else {
                                            }
                                          }}
                                          className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                        ></i>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <img
                                          src={im}
                                          className="imgPreview"
                                          onClick={(e) => {
                                            downloadHandler(e.target.src);
                                          }}
                                        />
                                        <i
                                          onClick={(e) => {
                                            let text = "want to remove image";
                                            if (window.confirm(text) == true) {
                                              formik.values.images = formik.values.images.filter(
                                                (obj) => obj !== formik.values.images[index]
                                              ); // create a new array without the object with id = 2
                                              let array = [...formik.values.images];
                                              formik.setFieldValue("images", array);
                                            } else {
                                            }
                                          }}
                                          className="fa fa-sharp fa-solid fa-trash img_dl_icon"
                                        ></i>
                                      </>
                                    );
                                  }
                                })
                              ) : (
                                <></>
                              )}
                              <input
                                type="file"
                                name="images"
                                multiple
                                className={
                                  "form-control" + (formik.touched.images && formik.errors.images ? " is-invalid" : "")
                                }
                                onChange={(e) => {
                                  let array1 = [...formik.values.images];
                                  let array2 = e.target.files;
                                  let mergedArray = [...array1, ...array2];
                                  formik.setFieldValue("images", mergedArray);
                                }}
                                // onBlur={(e) => {
                                //   formik.setFieldValue("images", e.target.files);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        {offerDetails.images_after_delivery && offerDetails.images_after_delivery.length > 0 ? (
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="form-group form-group-margin-bottom">
                                <label className="form-label">Images After Delivery</label>
                                {offerDetails.images_after_delivery.map((im) => {
                                  if (typeof im != "string") {
                                    return (
                                      <img
                                        src={URL.createObjectURL(im)}
                                        className="imgPreview"
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                    );
                                  } else {
                                    return (
                                      <img
                                        src={im}
                                        className="imgPreview"
                                        onClick={(e) => {
                                          downloadHandler(e.target.src);
                                        }}
                                      />
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="d-flex  justify-content-end p-4 ">
                          <div className="border border-danger rounded p-4 w-40">
                            <div
                              className="text-primary"
                              onClick={() =>
                                setOtherExpenses((prev) => {
                                  return { ...prev, main: !prev.main };
                                })
                              }
                            >
                              Add other expense
                            </div>
                            <div className="">
                              {otherExpense.main && (
                                <>
                                  <div className="d-flex flex-row row">
                                    <div className="form-group col-xl-8 xol-lg-8 ">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="enter field"
                                        name="expense_field"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.expense_field}
                                      />
                                    </div>
                                    <div className="form-group ms-1 col-xl-3 col-lg-3">
                                      <input
                                        className="form-control"
                                        name="expense_value"
                                        type="text"
                                        placeholder="enter value"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.expense_value}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <p>{formik.values?.expense_field}</p>
                              <p>{formik.values?.expense_value}</p>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <p>Total amount</p>
                              <p>{totalValues}</p>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-around">
                          {id && offerDetails?.offer_status === "draft" ? (
                            <>
                              <button
                                type="submit"
                                className="btn ripple  btn-primary add-user-form-submit-btn"
                                onClick={() => setBtnName("draft")}
                              >
                                Save as draft
                              </button>
                              <button
                                type="submit"
                                className="btn ripple  btn-primary add-user-form-submit-btn ms-4"
                                onClick={() => setBtnName("send")}
                              >
                                Save and send
                              </button>
                              <button
                                type="button"
                                className="btn ripple  btn-danger add-user-form-submit-btn w-25 ms-4"
                                onClick={() => navigate("/offerlist")}
                              >
                                Cancel
                              </button>
                            </>
                          ) : null}
                          {id && offerDetails?.offer_status !== "draft" ? (
                            <>
                              <button
                                type="submit"
                                className="btn ripple  btn-primary add-user-form-submit-btn ms-4"
                                onClick={() => setBtnName("send")}
                              >
                                Save and send
                              </button>
                              <button
                                type="button"
                                className="btn ripple  btn-danger add-user-form-submit-btn w-25 ms-4"
                                onClick={() => navigate("/offerlist")}
                              >
                                Cancel
                              </button>
                            </>
                          ) : null}
                          {id ? null : (
                            <>
                              <button
                                type="submit"
                                className="btn ripple  btn-primary add-user-form-submit-btn"
                                onClick={() => setBtnName("draft")}
                              >
                                Save as draft
                              </button>
                              <button
                                type="submit"
                                className="btn ripple  btn-primary add-user-form-submit-btn ms-4"
                                onClick={() => setBtnName("send")}
                              >
                                Save and send
                              </button>
                              <button
                                type="button"
                                className="btn ripple  btn-danger add-user-form-submit-btn w-25 ms-4"
                                onClick={() => navigate("/offerlist")}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                        </div>
                      </form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div>
          <section>
            <ToastContainer />
            <PageBanner props="My Dashboard" />
          </section>
          <section className="sptb">
            <div className="container">
              <div className="row">
                <SideBar />
                <div className="col-xl-9 col-lg-12 col-md-12">
                  <div className="card card-body">
                    <div className="page-header-wrapper">
                      <h3 className="d-flex justify-content-center mt-8" style={{ fontSize: "40px" }}>
                        {"you cannot edit this page "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}

export default Offers;
