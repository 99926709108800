import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../../context/GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBanner from "../PageBanner";

const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
  password: Yup.string().required("Password is a required field"),
});

function Login() {
  const navigate = useNavigate();
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [user, setUser] = globalCtx.user;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loginObj, setLoginObj] = new useState({
    email: "",
    password: "",
  });

  const onSubmit = async (values) => {
    let obj = { ...values };
    // return
    try {
      const resp = await serv.login(obj);
      console.log("response is",resp)
      if (resp?.token) {
        if (resp?.result.is_active == true || resp.result.is_active == 1) {
          setIsAuthentiCated(true);
          setTimeout(() => {
            navigate("/");
          }, 1000);
          setUser(resp.result);
        } else {
          toast(
            "Confirmation mail has been sent in your email. Please confirm your email to login."
          );
        }
      } else if (resp.message) {
        setErrorMsg(true);
      } else {
        notify(resp.error);
        setErrorMsg(resp);
      }
    } catch (err) {
      err = JSON.parse(err.message);
      // setErrorMsg(err.err);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const notify = (msg) => toast(`${msg}`);

  const formik = useFormik({
    initialValues: loginObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <section className="sptb">
      <PageBanner props="Sign In" />
      <div className="container customerpage user-auth-form-card">
        <div className="row">
          <div className="single-page">
            <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
              <div className="wrapper wrapper2">
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />
                <form
                  id="login"
                  onSubmit={formik.handleSubmit}
                  className="card-body"
                  tabIndex={500}
                >
                  <div className="mail">
                    <input
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className={
                        "form-control" +
                        (formik.touched.email && formik.errors.email
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label>E-Mail</label>
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="valid_feedbackMsg">
                      <p
                        style={{
                          color: "red",
                          marginTop: 10,
                          textAlign: "left",
                        }}
                        className="user-auth-msg"
                      >
                        {formik.errors.email}
                      </p>
                    </div>
                  ) : null}
                  <div className="passwd mb-0">
                    <input
                      type="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className={
                        "form-control" +
                        (formik.touched.password && formik.errors.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <label>Password</label>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="valid_feedbackMsg">
                      <p
                        style={{
                          color: "red",
                          marginTop: 10,
                          textAlign: "left",
                        }}
                        className="user-auth-msg"
                      >
                        {formik.errors.password}
                      </p>
                    </div>
                  ) : null}
                  <div className="form-group pt-3  mt-4">
                    <label className="custom-control form-checkbox">
                      <input type="checkbox" className="custom-control-input" />
                      <span className="custom-control-label text-dark ms-4 mt-1">
                        Remember me
                      </span>
                    </label>
                  </div>
                  <div className="submit">
                    <button
                      className="btn ripple  btn-primary btn-block"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                  <p className="mb-2">
                    <a>
                      <Link to={"/forgotpassword"}> Forgot Password </Link>
                    </a>
                  </p>
                  <p className="text-dark mb-0">
                    Don't have account?
                    <a className="text-primary ms-1">
                      <Link to={"/signup"}>Sign Up</Link>
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  // return (
  //     <main className="w-100 clearfix socialMediaTheme">
  //         {/* login page Start*/}
  //         <div className="loginpage d-flex">
  //             <div className="loginForm_left">
  //                 <div className="loginFormLeftInner">
  //                     <div className="loginLogo">
  //                         <img src="/images/profile/logo-white.png" className="img-fluid" />
  //                     </div>
  //                     <div className="login_heading">
  //                         <h2>Log in</h2>
  //                         <p>Lorem ipsum dolor sit amet, consectet</p>
  //                     </div>
  //                     <div className="logInform">
  //                         {showOtp ?
  //                             <div>
  //                                 <div className="mb-3 mb-sm-4 commonform">
  //                                     <label htmlFor="username" className="form-label">OTP</label>
  //                                     {/* <input type="text" className="form-control" id="username" defaultValue="michaelphilip@gmail.com" required /> */}
  //                                     <input
  //                                         className={
  //                                             "form-control" +
  //                                             (otp
  //                                                 ? ""
  //                                                 : "")
  //                                         }
  //                                         placeholder="123456"
  //                                         name="otp"
  //                                         onChange={(e) => setOtp(e.target.value)}
  //                                         value={otp}
  //                                     />
  //                                     {/* {formik.touched.email && formik.errors.email ? (
  //                                     <div className="valid_feedbackMsg">
  //                                         {formik.errors.email}
  //                                     </div>
  //                                 ) : null} */}
  //                                 </div>
  //                                 {errorMsg && (
  //                                     <div className="valid_feedbackMsg text-center">
  //                                         {errorMsg}
  //                                     </div>
  //                                 )}
  //                                 <div className="loginBtn">
  //                                     <button onClick={handleSubmitOtp} className="btn btnColor w-100">Log In</button>
  //                                 </div>
  //                             </div>
  //                             : <form onSubmit={formik.handleSubmit}>
  //                                 <div className="mb-3 mb-sm-4 commonform">
  //                                     <label htmlFor="username" className="form-label">Username</label>
  //                                     {/* <input type="text" className="form-control" id="username" defaultValue="michaelphilip@gmail.com" required /> */}
  //                                     <input
  //                                         className={
  //                                             "form-control" +
  //                                             (formik.touched.email && formik.errors.email
  //                                                 ? " is-invalid"
  //                                                 : "")
  //                                         }
  //                                         placeholder="john@mavefi.com"
  //                                         name="email"
  //                                         onChange={formik.handleChange}
  //                                         onBlur={formik.handleBlur}
  //                                         value={formik.values.email}
  //                                     />
  //                                     {formik.touched.email && formik.errors.email ? (
  //                                         <div className="valid_feedbackMsg">
  //                                             {formik.errors.email}
  //                                         </div>
  //                                     ) : null}
  //                                 </div>
  //                                 <div className="commonform">
  //                                     <label htmlFor="passwordLogin" className="form-label">Password</label>
  //                                     <div className="position-relative">
  //                                         {/* <input type="password" className="form-control" id="passwordLogin" defaultValue={123123123} /> */}
  //                                         <input
  //                                             className={
  //                                                 "form-control" +
  //                                                 (formik.touched.password && formik.errors.password
  //                                                     ? " is-invalid"
  //                                                     : "")
  //                                             }
  //                                             type={showPassword ? "text" : "password"}
  //                                             placeholder=""
  //                                             name="password"
  //                                             onChange={formik.handleChange}
  //                                             onBlur={formik.handleBlur}
  //                                             value={formik.values.password}
  //                                         />
  //                                         {formik.touched.password && formik.errors.password ? (
  //                                             <div className="valid_feedbackMsg">
  //                                                 {formik.errors.password}
  //                                             </div>
  //                                         ) : null}
  //                                         <span className="showHidetoggle">
  //                                             {!formik.errors.password ? (showPassword ?
  //                                                 (<img src="/images/profile/show_pass.svg" className="showLoginPass" onClick={handleShowPassword} />) :
  //                                                 (<img src="/images/profile/Hide-pass.svg" className="hideLoginPass" onClick={handleShowPassword} />)
  //                                             ) : ""}
  //                                         </span>
  //                                     </div>
  //                                 </div>
  //                                 <a href="javascript:void(0)" className="forget_pass my-3" onClick={() => navigate("/forgotpassword")}>Forgot Password?</a>
  //                                 {errorMsg && (
  //                                     <div className="valid_feedbackMsg text-center">
  //                                         {errorMsg}
  //                                     </div>
  //                                 )}
  //                                 <div className="loginBtn">
  //                                     <button type="submit" href="javascript:void(0);" className="btn btnColor w-100">Log In</button>
  //                                 </div>
  //                             </form>}
  //                     </div>
  //                     <div className="notA_member d-flex align-items-center">
  //                         <span />
  //                         <p className="mb-0">Not a member?</p>
  //                         <span />
  //                     </div>
  //                     <div className="loginPara text-center mt-3">
  //                         <p><a href="javascript:void(0)" className="join" onClick={() => navigate("/signup")}>Join</a> to unlock the best of Trading view.</p>
  //                         <p className="mt-3 mt-sm-4">By proceeding you agree to our <a href="javascript:void(0)" className="text-dark">Terms of Use</a> and confirm
  //                             you have read our <a href="javascript:void(0)" className="text-dark">Privacy and Cookie Statement</a>.</p>
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className="loginRight_img">
  //                 <img src="/images/profile/login_img.png" className="img-fluid" />
  //             </div>
  //         </div>
  //         {/* login page End */}
  //     </main>

  // )
}
export default Login;
