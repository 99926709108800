import PageBanner from "../PageBanner";
import { Formik, useFormik, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import ContactService from "../../services/ContactService";
import OfferService from "../../services/OfferService";
import { ToastContainer, toast } from "react-toastify";

const ValidateSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  subject: Yup.string().required("Subject is required"),
  description: Yup.string().required("Description is requried"),
});
const contactServ = new ContactService();
const offerServ = new OfferService();
function Contact() {
  const [formfield, setFormField] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
    offer_id: "",
    category: "",
  });
  const [shipping, setShipping] = useState({});
  const fetchShippingDetails = async () => {
    const shipping = await offerServ.getShipping();
    setShipping((prev) => shipping.data[0]);
  };
  useEffect(() => {
    fetchShippingDetails();
  }, []);
  const onSubmit = async (values, { resetForm }) => {
    let result = await contactServ.add(values);
    if (result) {
      toast.success("You will be contacted very soon");
      resetForm();
    }
  };
  const formik = useFormik({
    initialValues: formfield,
    onSubmit,
    validationSchema: ValidateSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  return (
    <div>
      <section>
        <PageBanner props="Contact Us" />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-4 col-md-6  py-2">
              <div>
                <div
                  // className="cover-image contact-bg rounded-3"
                  className="rounded-3"
                  data-bs-image-src="/assets/images/patterns/5.png"
                  style={{ backgroundColor: "lightblue" }}
                >
                  <div className="card-header bg-transparent d-grid py-4 border-bottom-0">
                    <h3 className="card-title">Contact Information</h3>
                    <span className="text-white">Fill up the Form our team will be get back to you</span>
                  </div>
                  <div className="card-body border-top">
                    <div className="conatct-list">
                      <a href="javascript:void(0);" className="media mt-0 mb-6">
                        <div className="media-icon">
                          <i className="fe fe-phone" />
                        </div>
                        <div className="media-body">
                          <h6 className="mt-1 fs-16">{shipping.phone ? shipping.phone : ""}</h6>
                          <small className=" fs-13">Free Support!</small>
                        </div>
                      </a>
                      {/* <a href="javascript:void(0);" className="media mb-6">
                        <div className="media-icon">
                          <i className="fe fe-clock" />
                        </div>
                        <div className="media-body">
                          <h6 className="mt-1 fs-16">Mon-Sat(10:00-19:00)</h6>
                          <small className=" fs-13">Working Hours!</small>
                        </div>
                      </a> */}
                      <a href="javascript:void(0);" className="media mb-6">
                        <div className="media-icon">
                          <i className="fe fe-map-pin" />
                        </div>
                        <div className="media-body">
                          <h6 className="mt-1 fs-16">
                            {shipping.street_address ? shipping.street_address : ""} ,{" "}
                            {shipping.city ? shipping.city : null}
                          </h6>
                          <small className=" fs-13">
                            {shipping.state ? shipping.state : ""} , {shipping.country ? shipping.country : ""}
                          </small>
                        </div>
                      </a>
                      <a href="javascript:void(0);" className="media mb-4">
                        <div className="media-icon">
                          <i className="fe fe-mail" />
                        </div>
                        <div className="media-body">
                          <h6 className="mt-1 fs-16">{shipping.email ? shipping.email : ""}</h6>
                          <small className=" fs-13">Support us!</small>
                        </div>
                      </a>
                    </div>
                    <div className="icons d-flex  py-3 mt-6">
                      <a href="javascript:void(0);" className="me-3 conatct-list-icon">
                        <i className="fe fe-instagram fs-15 text-light" />
                      </a>
                      <a href="javascript:void(0);" className="me-3 conatct-list-icon">
                        <i className="fe fe-facebook fs-15 text-light" />
                      </a>
                      <a href="javascript:void(0);" className="me-3 conatct-list-icon">
                        <i className="fe fe-twitter fs-15 text-light" />
                      </a>
                      <a href="javascript:void(0);" className="me-3 conatct-list-icon">
                        <i className="fe fe-linkedin fs-15 text-light" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-7 col-md-6 py-2">
              <div className="card mb-0">
                <div className="card-header">
                  <h3 className="card-title">Contact Us</h3>
                </div>
                <div className="card-body">
                  <Formik>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <div className="form-group">
                            <label className="form-label">Name*</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                "form-control" + (formik.touched.name && formik.errors.name ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.name && formik.errors.name ? dispErrorMsg("Name is required") : null}
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Email*</label>
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            className={
                              "form-control" + (formik.touched.email && formik.errors.email ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.email && formik.errors.email ? dispErrorMsg("Email is required") : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Subject*</label>
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            className={
                              "form-control" + (formik.touched.subject && formik.errors.subject ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.subject && formik.errors.subject ? dispErrorMsg("Subject equired") : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Offer Id</label>
                          <input
                            type="text"
                            name="offer_id"
                            placeholder="Offer Id"
                            value={formik.values.offer_id}
                            onChange={formik.handleChange}
                            className={"form-control"}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">Category</label>
                          <select
                            type="text"
                            name="category"
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            className={"form-control"}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select category</option>
                            <option value="product">Product</option>
                            <option value="payment">Payment</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="form-group col-md-12">
                          <textarea
                            name="description"
                            rows={6}
                            placeholder="Description ...."
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className={
                              "form-control" +
                              (formik.touched.description && formik.errors.description ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.description && formik.errors.description
                            ? dispErrorMsg("Description is required")
                            : null}
                        </div>

                        <div className="text-end">
                          <button type="submit" className="btn ripple  btn-primary">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Contact;
