import util from "../util/util";
import axios from "axios";

export default class OfferService {
  async add(payload) {
    return util.sendApiRequestWithFiles("/offer", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  edit(payload) {
    return util.sendApiRequestWithFiles("/offer", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  clone(dataId) {
    return util.sendApiRequest(`/offer/clone/${dataId}`, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  list(payload) {
    return util
      .sendApiRequest("/offer/list", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  delete(dataId) {
    return util.sendApiRequest("/offer/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  GetPdf(dataId) {
    return util
      .sendApiRequest("/offer/downloadPdf/" + dataId + "/" + "user", "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  getOfferInfo(dataId) {
    return util
      .sendApiRequest("/offer/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  setTrackingInfo(payload) {
    return util.sendApiRequest("/offer/trackinginfo", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  sendEmail(body) {
    return util.sendApiRequest("/offer/send/email", "POST", true, body).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getShipping() {
    return util.sendApiRequest("/offer/shipping/details", "GET", true, {}).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
