import util from "../util/util";
export default class PaymentService {
    async submitpaymentinfo(payload){
        return util.sendApiRequest("/user/paymentinfo", "POST", true, payload).then(
            (response) => {
              return response;
            },
            (error) => {
              throw error;
            }
          );
    }
    async paymentHistory(userId){
      return util.sendApiRequest("/offer/payment/history/"+userId,"GET",true,{}).then(
        (response) =>{
          return response;
        },
        (error) =>{
          throw error;
        }
      )
    }
    async paymentMethodList(userId){
      return util.sendApiRequest("/user/payment/method/list/"+userId,"GET",true,{}).then(
        (response) =>{
          return response;
        },
        (error) =>{
          throw error;
        }
      )
    }
    async deletePaymentMethod(id){
      return util.sendApiRequest("/user/method/delete/"+id,"POST",true,{}).then(
        (response)=>{
          return response
        },
        (error)=>{
          throw error
        }
      )
    }
    async getPaymentinfo(id){
      return util.sendApiRequest(`/user/paymentinfo/${id}`,"GET",true,{})
      .then(
        (response) =>{
          return response;
        },
        (response) =>{
          return response;
        }

      )
    }
    async getPaymentMethodInfo(methodId){
      return util.sendApiRequest("/user/paymentmethod/"+methodId,"GET",true,{}).then(
        (response)=>{
          return response
        },
        (response)=>{
          return response
        }
      )
    }
}