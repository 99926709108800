import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";
import UserService from "../services/UserService";
import { useLocation } from "react-router-dom";
import NotificationService from "../services/NotificationsService";
import moment, { duration } from "moment";
const serv = new UserService();
const notificationServ = new NotificationService();
export default function Navbar() {
  const location = useLocation();
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [allNotifications, setAllNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [user, setUser] = globalCtx.user;
  const navigate = useNavigate();
  const [checkUserInfo, setCheckUserInfo] = useState(true);
  const [search, setSearch] = useState({
    start: 0,
    length: 6,
  });

  useEffect(() => {
    // console.log("The User is:", user);
    // console.log("User id is", user.id);
    // getUser(user.id);
  }, [checkUserInfo]);

  const logoutHandler = () => {
    window.user = null;
    localStorage.clear();
    setCheckUserInfo(false);
    setUser(null);
    setIsAuthentiCated(false);
    navigate("/");
  };

  const getUser = async (id) => {
    if (id) {
      try {
        const response = await serv.getUser(id);
        console.log(response);
      } catch (err) {}
    }
  };

  const getNotifications = async () => {
    try {
      const response = await notificationServ.listAll(search);
      if (response.data.rows) {
        setAllNotifications(response.data.rows);
      }
    } catch (err) {}
  };

  const notificationClickHandler = async (id) => {
    let readData = { is_viewed: true, id: id };
    try {
      const response = await notificationServ.edit(readData);
      if (response.message) {
        // console.log(response.messa);
        getNotifications();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
    }, 5000);
    return () => clearInterval(interval);
  }, [search]);

  return (
    <div>
      <header className="header-search header-logosec p-2 header-icons">
        <div className="container">
          <div className="d-flex">
            <div className="desktoplogo">
              <Link to="/">
                <img src="/assets/images/brand/logo1.png" alt />
              </Link>
            </div>
            <div className="desktoplogo-1">
              <Link to="/">
                <img src="/assets/images/brand/logo1.png" alt />
              </Link>
            </div>
            <div className="d-flex ms-auto header-right-icons header-search-icon">
              <div className="dropdown d-md-flex notifications notify-message">
                <a className="nav-link icon" data-bs-toggle="dropdown">
                  <i className="fe fe-bell" />
                  <span className="pulse bg-success" />
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow py-0">
                  <div className="p-4 text-start bg-light border-bottom rounded-top-5  bg-indigo-lightest">
                    <div className="d-flex align-items-center">
                      <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">Notification</h6>
                      <p className="mb-0 font-weight-semibold tx-muted tx-12 ms-auto">
                        Total: {allNotifications.length}
                      </p>
                    </div>
                  </div>
                  <div className="notifications-menu header-dropdown-list header-dropdown-listCust  notify-scroll">
                    {allNotifications.map((el) => {
                      return (
                        <a
                          href="javascript:void(0);"
                          className="dropdown-item notification-item d-flex"
                          onClick={() => notificationClickHandler(el.id)}
                        >
                          {/* <div className="text-primary bg-primary-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-bell text-primary" />
                          </div> */}
                          <div className="notification-itemData">
                            <h6 className={"mb-1 " + (el.is_viewed ? "font-Wcust400" : "")}>{el.notification}</h6>
                            <div className="small text-muted">{moment(el.createdAt).fromNow()}</div>
                          </div>
                        </a>
                      );
                    })}
                    {/* 
                    <a href="javascript:void(0);" className="dropdown-item d-flex">
                      <div className="text-primary bg-primary-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-mail text-primary" />
                      </div>
                      <div>
                        <h6 className="mb-1">Commented on your post.</h6>
                        <div className="small text-muted">3 hours ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-secondary bg-secondary-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-user text-secondary" />
                      </div>
                      <div>
                        <h6 className="mb-1">New User Registered.</h6>
                        <div className="small text-muted">1 day ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-success bg-success-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-thumbs-up text-success" />
                      </div>
                      <div>
                        <h6 className="mb-1">Someone likes our posts</h6>
                        <div className="small text-muted">5 mins ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-warning bg-warning-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-upload text-warning" />
                      </div>
                      <div>
                        <h6 className="mb-1">New file has been uploaded</h6>
                        <div className="small text-muted">50 sec ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-danger bg-danger-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-alert-circle text-danger" />
                      </div>
                      <div>
                        <h6 className="mb-1">System alert</h6>
                        <div className="small text-muted">2 days ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-warning bg-warning-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-server text-warning" />
                      </div>
                      <div>
                        <h6 className="mb-1">Server Rebooted</h6>
                        <div className="small text-muted">45 mins ago</div>
                      </div>
                    </a>
                    <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                      <div className="text-secondary bg-secondary-transparent fs-18 me-3 notify-icon">
                        <i className="fe fe-layers text-secondary" />
                      </div>
                      <div>
                        <h6 className="mb-1">Completed One task</h6>
                        <div className="small text-muted">3 mins ago</div>
                      </div>
                    </a>*/}
                  </div>
                  <div className="dropdown-footer bg-light  p-3">
                    <button
                      onClick={() =>
                        setSearch({
                          start: 0,
                        })
                      }
                      className="btn  btn-primary w-md tx-13"
                      disabled={allNotifications.length > 8 ? true : false}
                    >
                      View All Notifications
                    </button>
                  </div>
                </div>
              </div>
              {/* NOTIFICATIONS */}
              <div className="d-md-flex m-auto" style={{ width: "90px" }}>
                <ul className="m-auto">
                  {isAuthentiCated == true ? (
                    <li className="navbar-dashboard-text-wrapper">
                      <a href="javascript:void(0);" className="text-dark" data-bs-toggle="dropdown">
                        <i className="fa fa-home me-2" />
                        <span className="navbar-dashboard-text">
                          {user?.first_name}
                          <i className="fa fa-caret-down ms-2" />
                        </span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow drpdown-menu-show">
                        <a className="dropdown-item navbar-auth-dropdown">
                          <Link to={"/dashboard"}>
                            <i className="dropdown-icon  fe fe-user text-primary" />
                            My Dashboard
                          </Link>
                        </a>
                        <a className="dropdown-item navbar-auth-dropdown">
                          <Link to={"/offerlist"}>
                            <i className="dropdown-icon  fe fe-box text-primary" />
                            Offer History
                          </Link>
                        </a>
                        <a className="dropdown-item navbar-auth-dropdown">
                          <Link to={"/payment"}>
                            <i className="dropdown-icon fa fa-money text-primary" />
                            Payout Preference
                          </Link>
                        </a>
                        <a className="dropdown-item navbar-auth-dropdown" onClick={logoutHandler}>
                          <i className="dropdown-icon icon icon-power text-primary" />
                          Log out
                        </a>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <span>
                        <a className="btn ripple  btn-danger">
                          <Link to={"/login"} className="text-color-white">
                            Sign In/Sign Up
                          </Link>
                        </a>
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="horizontal-header clearfix ">
        <div className="container">
          <a id="horizontal-navtoggle" className="animated-arrow">
            <span />
          </a>
          <span className="smllogo">
            <img src="/assets/images/brand/logo1.png" width={120} alt="img" />
          </span>
          <span className="smllogo-white " style={{ background: "white" }}>
            <img src="/assets/images/brand/logo1.png" width={120} alt="img" />
          </span>
          <a href="tel:245-6325-3256" className="callusbtn">
            <i className="fa fa-phone" aria-hidden="true" />
          </a>
        </div>
      </div>
      <div className="header-style horizontal-main clearfix">
        <div className="horizontal-mainwrapper container clearfix">
          <nav className="horizontalMenu clearfix d-md-flex">
            <ul className="horizontalMenu-list">
              <li aria-haspopup="true" className={location.pathname == "/" && " activeLiA"}>
                <a href="javascript:void(0);">
                  <Link to={"/"}> Home </Link>
                </a>
              </li>
              <li aria-haspopup="true" className={location.pathname == "/offer" && " activeLiA"}>
                <Link to={isAuthentiCated ? "/offer" : "/login"}>Start Selling</Link>
              </li>
              <li aria-haspopup="true" className={location.pathname == "/about" && " activeLiA"}>
                <Link to="/about">About Us</Link>
              </li>
              <li aria-haspopup="true" className={location.pathname == "/faqs" && " activeLiA"}>
                <Link to="/faqs">FAQs</Link>
              </li>
              <li aria-haspopup="true" className={location.pathname == "/contact" && " activeLiA"}>
                <Link to="/contact"> Support</Link>
              </li>
            </ul>
            {/* <ul className="mb-0">
        <li aria-haspopup="true" className="d-none d-lg-block ">
          <span><a className="btn ripple  btn-danger ad-post" href="register.html"><i className="fa fa-cart-plus me-2 text-white fs-16" />Become a Seller</a></span>
        </li>
      </ul> */}
          </nav>
        </div>
      </div>
    </div>
  );
  return (
    <div className="header-main">
      {/* Mobile Header */}
      {/* <div className="horizontal-header clearfix ">
        <div className="container">
          <a id="horizontal-navtoggle" className="animated-arrow">
            <span />
          </a>
          <span className="smllogo">
            <img src="/assets/images/brand/logo1.png" width={120} alt="img" />
          </span>
          <span className="smllogo-white">
            <img src="/assets/images/brand/logo.png" width={120} alt="img" />
          </span>
          <a href="tel:245-6325-3256" className="callusbtn">
            <i className="fa fa-phone" aria-hidden="true" />
          </a>
        </div>
      </div> */}
      {/* /Mobile Header */}

      <div className="header-style horizontal-main clearfix navbar-fixed">
        <div className="horizontal-mainwrapper container clearfix">
          <nav className="header-search header-logosec p-2 header-icons clearfix ">
            <div className="container">
              <div className="d-flex">
                <div className="desktoplogo">
                  <a href="index.html">
                    <img src="/assets/images/brand/logo1.png" alt />
                  </a>
                </div>
                <div className="desktoplogo-1">
                  <a href="index.html">
                    <img src="/assets/images/brand/logo.png" alt />
                  </a>
                </div>
                <div className="d-flex ms-auto header-right-icons header-search-icon">
                  <div className="dropdown d-md-flex notifications notify-message">
                    <a className="nav-link icon" data-bs-toggle="dropdown">
                      <i className="fe fe-bell" />
                      <span className="pulse bg-success" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow py-0">
                      <div className="p-4 text-start bg-light border-bottom rounded-top-5  bg-indigo-lightest">
                        <div className="d-flex align-items-center">
                          <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">Notification</h6>
                          <p className="mb-0 font-weight-semibold tx-muted tx-12 ms-auto">Total: 7</p>
                        </div>
                      </div>
                      <div className="notifications-menu header-dropdown-list  notify-scroll">
                        <a href="javascript:void(0);" className="dropdown-item d-flex">
                          <div className="text-primary bg-primary-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-mail text-primary" />
                          </div>
                          <div>
                            <h6 className="mb-1">Commented on your post.</h6>
                            <div className="small text-muted">3 hours ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-secondary bg-secondary-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-user text-secondary" />
                          </div>
                          <div>
                            <h6 className="mb-1">New User Registered.</h6>
                            <div className="small text-muted">1 day ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-success bg-success-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-thumbs-up text-success" />
                          </div>
                          <div>
                            <h6 className="mb-1">Someone likes our posts</h6>
                            <div className="small text-muted">5 mins ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-warning bg-warning-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-upload text-warning" />
                          </div>
                          <div>
                            <h6 className="mb-1">New file has been uploaded</h6>
                            <div className="small text-muted">50 sec ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-danger bg-danger-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-alert-circle text-danger" />
                          </div>
                          <div>
                            <h6 className="mb-1">System alert</h6>
                            <div className="small text-muted">2 days ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-warning bg-warning-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-server text-warning" />
                          </div>
                          <div>
                            <h6 className="mb-1">Server Rebooted</h6>
                            <div className="small text-muted">45 mins ago</div>
                          </div>
                        </a>
                        <a href="javascript:void(0);" className="dropdown-item d-flex border-top">
                          <div className="text-secondary bg-secondary-transparent fs-18 me-3 notify-icon">
                            <i className="fe fe-layers text-secondary" />
                          </div>
                          <div>
                            <h6 className="mb-1">Completed One task</h6>
                            <div className="small text-muted">3 mins ago</div>
                          </div>
                        </a>
                      </div>
                      <div className="dropdown-footer bg-light  p-3">
                        <a href="javascript:void(0);" className="btn  btn-primary w-md tx-13">
                          View All Notifications
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="d-md-flex" style={{ width: "90px" }}>
                    <ul className="navbar-dashboard-dropdown">
                      {isAuthentiCated == true ? (
                        <li className="navbar-dashboard-text-wrapper">
                          <a href="javascript:void(0);" className="text-dark" data-bs-toggle="dropdown">
                            <i className="fa fa-home me-2" />
                            <span className="navbar-dashboard-text">
                              {user?.first_name}
                              <i className="fa fa-caret-down ms-2" />
                            </span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow drpdown-menu-show">
                            <a className="dropdown-item navbar-auth-dropdown">
                              <Link to={"/editprofile"}>
                                <i className="dropdown-icon  fe fe-user text-primary" />
                                My Dashboard
                              </Link>
                            </a>
                            <a className="dropdown-item navbar-auth-dropdown">
                              <Link to={"/offerlist"}>
                                <i className="dropdown-icon  fe fe-box text-primary" />
                                Offer History
                              </Link>
                            </a>
                            <a className="dropdown-item navbar-auth-dropdown">
                              <Link to={"/payment"}>
                                <i className="dropdown-icon fa fa-money text-primary" />
                                Payout Preference
                              </Link>
                            </a>
                            <a className="dropdown-item navbar-auth-dropdown" onClick={logoutHandler}>
                              <i className="dropdown-icon icon icon-power text-primary" />
                              Log out
                            </a>
                          </div>
                        </li>
                      ) : (
                        <li aria-haspopup="true" className="d-none d-lg-block ">
                          <span>
                            <a className="btn ripple  btn-danger">
                              <Link to={"/login"} className="text-color-white">
                                Sign In/Sign Up
                              </Link>
                            </a>
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <nav className="horizontalMenu clearfix d-md-flex">
            <ul className="horizontalMenu-list">
              <li aria-haspopup="true">
                <a href="javascript:void(0);">
                  <Link to={"/"}> Home </Link>
                </a>
              </li>
              <li aria-haspopup="true">
                <Link to={isAuthentiCated ? "/offer" : "/login"}>Start Selling</Link>
              </li>
              <li aria-haspopup="true">
                <Link to="/about">About Us</Link>
              </li>
              <li aria-haspopup="true">
                <Link to="/faqs">FAQs</Link>
              </li>
              <li aria-haspopup="true">
                <Link to="/contact"> Support</Link>
              </li>

              {/* <li aria-haspopup="true" className="d-lg-none mt-5 pb-5 mt-lg-0">
                <span>
                  <a
                    className="btn ripple  btn-info"
                    href="javascript:void(0);"
                  >
                    Register Now
                  </a>
                </span>
              </li> */}
              {/* 
              // <li aria-haspopup="true">
                
              // <Link to={"/offerlist"}>
              //           {/* <i className="dropdown-icon  fe fe-box text-primary" /> */}
              {/* //           Offer
              //         </Link>
              // </li> */}

              {/* <li aria-haspopup="true">
                <button style={{}} className="btn btn-outline-success">
                  <Link to={isAuthentiCated ? "/offerlist" : "/login"}>Offer</Link>
                </button>
              </li> */}
            </ul>
            {/* <ul className="navbar-dashboard-dropdown">
              {isAuthentiCated == true ? (
                <li className="navbar-dashboard-text-wrapper">
                  <a
                    href="javascript:void(0);"
                    className="text-dark"
                    data-bs-toggle="dropdown"
                  >
                    <i className="fa fa-home me-2" />
                    <span className="navbar-dashboard-text">
                      {user?.first_name}
                      <i className="fa fa-caret-down ms-2" />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow drpdown-menu-show">
                    <a className="dropdown-item navbar-auth-dropdown">
                      <Link to={"/editprofile"}>
                        <i className="dropdown-icon  fe fe-user text-primary" />
                        Profile
                      </Link>
                    </a>
                    <a className="dropdown-item navbar-auth-dropdown">
                      <Link to={"/offerlist"}>
                        <i className="dropdown-icon  fe fe-box text-primary" />
                        Offer History
                      </Link>
                    </a>
                    <a className="dropdown-item navbar-auth-dropdown">
                      <Link to={"/payment"}>
                        <i className="dropdown-icon fa fa-money text-primary" />
                        Payout Preference
                      </Link>
                    </a>
                    <a
                      className="dropdown-item navbar-auth-dropdown"
                      onClick={logoutHandler}
                    >
                      <i className="dropdown-icon icon icon-power text-primary" />
                      Log out
                    </a>
                  </div>
                </li>
              ) : (
                <li aria-haspopup="true" className="d-none d-lg-block ">
                  <span>
                    <a className="btn ripple  btn-danger">
                      <Link to={"/login"} className="text-color-white">
                        Sign In/Sign Up
                      </Link>
                    </a>
                  </span>
                </li>
              )}
            </ul> */}
          </nav>
        </div>
      </div>
    </div>
  );
}
