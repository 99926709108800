import util from "../util/util";
export default class ContactService {
  async add(payload) {
    return util
      .sendApiRequest("/contactus/details", "POST", false, payload)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw error;
        }
      );
  }
}
