import util from "../util/util";

export default class OfferService {
  addTestimonial(payload) {
    return util.sendApiRequest("/testimonial", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  deleteTestimonial(dataId) {
    return util.sendApiRequest("/testimonial/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getTestimonialInfo(dataId) {
    return util
      .sendApiRequest("/testimonial/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  editTestimonial(id, payload) {
    return util.sendApiRequest("/testimonial/", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  listApi() {
    return util.sendApiRequest("/testimonial/list", "POST", true,{}).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
