import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import OfferService from "../../services/OfferService";
import UserService from "../../services/UserService";
import moment from "moment-timezone";
import PageBanner from "../PageBanner";
import SideBar from "../seller/SideBar";
import PaymentService from "../../services/PaymentService";
import * as Yup from "yup";
import { useFormik, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import download from "downloadjs";
moment.tz.setDefault(process.env.REACT_APP_MOMENT_TIME_ZONE);
const service = new OfferService();
const userServ = new UserService();
const paymentServ = new PaymentService();
const ValidateSchema = Yup.object({
  tracking_num: Yup.string().required("Required"),
  shipment_company: Yup.string().required("Required"),
});
let offerId;
function PreviewOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  offerId = id;
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOfferDetails] = useState({});
  const [offerProducts, setOfferProducts] = useState([]);
  const [showTracking, setShowTracking] = useState({ isOpen: false, id: offerId });
  const [userDetails, setUserDetails] = useState({});
  const [shippingDetails, setShippingDetails] = useState({});
  const [trackingDetails, setTrackingDetails] = useState({
    tracking_num: "",
    shipment_company: "",
    tracking_url: "",
  });

  useEffect(() => {
    fetchOfferList(offerId);
  }, []);

  const fetchOfferList = async (id) => {
    setIsLoading(true);
    try {
      const response = await service.getOfferInfo(id);
      let userId = response?.offer?.user_id;
      if (userId) {
        const userData = await userServ.getUser(userId);
        setUserDetails(userData);
      }
      const shipping = await service.getShipping();
      setShippingDetails(shipping.data[0]);
      if (response) {
        if (response.offer.images) {
          response.offer.images = response.offer.images.split(",");
        } else {
          response.offer.images = [];
        }
        if (response.offer.images_after_delivery) {
          response.offer.images_after_delivery = response.offer.images_after_delivery.split(",");
        } else {
          response.offer.images_after_delivery = [];
        }
        setOfferDetails(response.offer);
        setOfferProducts(response.offer.offers_products);
      }
    } catch (err) {}
    setIsLoading(false);
  };
  const fetchPaymentMethod = async (userId) => {
    const response = await service.getOfferInfo(id);
    let user_Id = response?.offer?.user_id;
    const result = await paymentServ.getPaymentinfo(user_Id);
    setPaymentDetails((prev) => result.data[0]);
  };
  const downloadPdf = async () => {
    try {
      let response = await service.GetPdf(offer.id);
      window.open(response.data);
    } catch (err) {
      throw err;
    }
  };
  const downloadHandler = async (filePath) => {
    try {
      // download(filePath);
      window.open(filePath, "_blank");
    } catch (err) {
      return false;
    }
  };
  const sendMessage = (id) => {
    // window.confirm("id is ",id)
    navigate("/message/" + id);
  };
  const trackingSubmit = async (values) => {
    let payload = { ...values };
    payload.id = showTracking.id;
    payload.offer_status = "shipped";

    let result = await service.setTrackingInfo(payload);
    if (result.message) {
      toast.success(result.message);
      setShowTracking((prev) => {
        return { ...prev, isOpen: false };
      });
      fetchOfferList(offerId);
    }
  };
  const formik = useFormik({
    initialValues: trackingDetails,
    onSubmit: trackingSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: ValidateSchema,
  });
  useEffect(() => {
    fetchPaymentMethod(formik.values.user_id);
  }, [formik.values.user_id]);
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="loadingImg">
        <img src="/assets/images/loader.svg" />
      </div>
    );
  }
  return (
    <div>
      <section>
        <PageBanner props="Invoice" />
      </section>
      {showTracking.isOpen == true && (
        <>
          <div className="modal d-block mt-5 pt-5" tabIndex={-1} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Tracking Details</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      setShowTracking((prev) => {
                        return { ...prev, isOpen: false };
                      })
                    }
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Formik>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Tracking Number</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Tracking Number"
                              value={formik.values.tracking_num}
                              name="tracking_num"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.tracking_num &&
                            formik.errors &&
                            formik.errors?.tracking_num
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Shipment Company</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Shipment Company"
                              value={formik.values.shipment_company}
                              name="shipment_company"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.shipment_company &&
                            formik.errors &&
                            formik.errors?.shipment_company
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>

                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">Tracking Url</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Tracking Url"
                              value={formik.values.tracking_url}
                              name="tracking_url"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched &&
                            formik.touched.tracking_url &&
                            formik.errors &&
                            formik.errors?.tracking_url
                              ? dispErrorMsg("Required")
                              : null}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" className="btn btn-primary">
                            Ok
                          </button>
                        </div>
                      </div>
                    </form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </>
      )}
      <section className="sptb">
        <div className="container">
          <div className="card mb-0">
            <div className="card-header">
              <h3 className="card-title">#INV-{offer?.id}</h3>
              <div className="card-options">
                <Link to="/offerlist">
                  <button type="button" className="btn ripple  btn-sm btn-secondary me-2">
                    Back
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              <div className="row ">
                <div className="col-lg-6 ">
                  <p className="h3">Client Inc</p>
                  <address>
                    {userDetails.address_1}
                    <br />
                    {userDetails.state}, {userDetails.city}
                    <br />
                    {userDetails.email}
                  </address>
                </div>
                <div className="col-lg-6 text-end">
                  <p className="h3">Invoice To</p>
                  <address>
                    {shippingDetails?.first_name} {shippingDetails?.last_name}
                    <br />
                    {shippingDetails?.street_address}
                    <br />
                    {shippingDetails?.city}, {shippingDetails?.state}
                    <br />
                    {shippingDetails?.postal_code}
                    <br />
                    {shippingDetails?.email}
                  </address>
                </div>
              </div>
              <div className="text-dark">
                <p className="mb-3 mt-5">
                  <span className="font-weight-semibold">Offer Date : </span>
                  {moment(offer?.createdAt?.replace("T", " ")).format("MM-DD-YYYY")}
                </p>
              </div>
              <div className="table-responsive push">
                <table className="table table-bordered table-hover text-nowrap">
                  <tbody>
                    <tr>
                      <th className="text-center " />
                      <th>Item Lists</th>
                      <th>UPC</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-end">Price</th>
                      <th className="text-end">Total</th>
                    </tr>
                    {offerProducts.map((data, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <p className="font-w600 mb-1">
                                Name - {data.product_name} Brand - {data.brand}{" "}
                              </p>
                              <div className="text-muted">{data.description}</div>
                            </td>
                            <td>{data.upc}</td>
                            <td className="text-center">{data.quantity}</td>
                            <td className="text-end">{data.price}</td>
                            <td className="text-end"> {data.quantity * data.price}</td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <td colSpan={5} className="font-w600 text-end">
                        {offer.expense_field}
                      </td>
                      <td className="text-end">{offer?.expense_value}</td>
                    </tr>
                    <tr>
                      <td colSpan={5} className="font-w600 text-end">
                        Total Amount
                      </td>
                      <td className="text-end">{offer?.total_price}</td>
                    </tr>

                    <tr>
                      <td colSpan={6} className="text-end">
                        <div className="m-4 mt-1 text-start">
                          <div className="mb-2">
                            <h4 className="form-label">Images</h4>
                            {offer.images?.map((image) => {
                              return (
                                <>
                                  <img
                                    src={image}
                                    style={{ float: "left" }}
                                    className="imgPreview"
                                    onClick={(e) => {
                                      downloadHandler(e.target.src);
                                    }}
                                  />
                                </>
                              );
                            })}
                          </div>
                          <div style={{ clear: "both" }}></div>
                          {offer.images_after_delivery && offer.images_after_delivery.length > 0 ? (
                            <div className="mb-2">
                              <h4 className="form-label">Images After Delivery</h4>
                              {offer.images_after_delivery?.map((image) => {
                                return (
                                  <>
                                    <img
                                      src={image}
                                      className="imgPreview"
                                      style={{ float: "left" }}
                                      onClick={(e) => {
                                        downloadHandler(e.target.src);
                                      }}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                          <div style={{ clear: "both" }}></div>
                          <h4 className="form-label mt-3">Selected Payout Method</h4>
                          <h5 className="fs-6 fw-light">
                            {paymentDetails.platform}({paymentDetails.details})
                          </h5>
                          <h4 className="form-label mt-3">Remarks</h4>
                          <h5 className="fs-6 fw-light">{offer.customerRemarks}</h5>
                          {offer.tracking_num && offer.tracking_num !== "null" ? (
                            <>
                              <h4 className="form-label mt-3">Tracking Info</h4>
                              <div className="d-flex flex-column">
                                <h5 className="fs-6 fw-light ms-1">Tracking Number - {offer.tracking_num}</h5>
                                <h5 className="fs-6 fw-light ms-1">Shipment Company - {offer.shipment_company}</h5>
                                <h5 className="fs-6 fw-light ms-1">Tracking Url - {offer.tracking_url}</h5>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn ripple  btn-info mx-2"
                          disabled={offer.offer_status == "shipped" || offer.offer_status == "approved" ? false : true}
                          onClick={() => {
                            setShowTracking((prev) => {
                              return {
                                ...prev,
                                isOpen: true,
                              };
                            });
                          }}
                        >
                          <i className="fa fa-truck"></i> Mark as Shipped
                        </button>
                        <button type="button" className="btn ripple  btn-info mx-2" onClick={() => downloadPdf()}>
                          <i className="icon icon-printer" /> Print invoice
                        </button>

                        <button type="button" className="btn ripple  btn-info" onClick={() => sendMessage(offer?.id)}>
                          <i className="icon icon-text" /> Send message
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PreviewOffer;
