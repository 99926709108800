import { useState, useEffect } from "react";
import PageBanner from "../PageBanner";
import SideBar from "../seller/SideBar";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import PaymentService from "../../services/PaymentService";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";

const paymentService = new PaymentService();
const userServ = new UserService();
const ValidateSchema = Yup.object({
  method: Yup.string().required("Required"),
  method_details: Yup.string().required("Required"),
  label: Yup.string().required("Required"),
});
function PaymentInfo() {
  const [paymentMethod, setPaymentMethod] = useState({
    method: "",
    method_details: "",
    label: "",
  });
  const [userDetails, setUserDetails] = useState({});
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  let user_id = JSON.parse(localStorage.getItem("user")).id;
  async function getPaymentHistory() {
    const result = await paymentService.paymentHistory(user_id);
    const response = await paymentService.paymentMethodList(user_id);
    const userData = await userServ.getUser(user_id);
    if (userData) {
      userData.payout_preferences = userData.payout_preferences.split(",");
      setUserDetails((prev) => userData);
    }
    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        let index = response.data[i].platform.indexOf("-");
        const platform_label = response.data[i].platform;
        response.data[i].platform = platform_label.substring(0, index);
        response.data[i].label = platform_label.substring(index + 1);
      }
      setPaymentMethodList((prev) => response.data);
    }
    setPaymentHistory((prev) => result);
  }
  useEffect(() => {
    getPaymentHistory();
  }, []);
  const onSubmit = async () => {
    const platform = new String().concat(formik.values.method, "-", formik.values.label);
    const details = {
      platform: platform,
      details: formik.values.method_details,
    };
    const result = await paymentService.submitpaymentinfo(details);
    if (result) {
      toast.success("Your payout preference is saved");
      const response = await paymentService.paymentMethodList(user_id);
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          let index = response.data[i].platform.indexOf("-");
          const platform_label = response.data[i].platform;
          response.data[i].platform = platform_label.substring(0, index);
          response.data[i].label = platform_label.substring(index + 1);
        }
        setPaymentMethodList((prev) => response.data);
      }
    }
  };
  const formik = useFormik({
    initialValues: paymentMethod,
    onSubmit,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: ValidateSchema,
  });
  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  const deleteMethod = async (id) => {
    if (window.confirm("Are you sure you want to delete this payment method ?")) {
      let result = await paymentService.deletePaymentMethod(id);
      console.log("result of delete payment method request is", result);
      if (result.success) {
        toast.success(result.message);
        getPaymentHistory();
      } else {
        toast.error(result.message);
      }
    }
  };
  return (
    <div>
      <section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <PageBanner props="Payments" />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <SideBar />

            <div className="col-xl-9 col-lg-12 col-md-12 ">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-5">
                  <div className="card mb-0">
                    <div className="card-header">
                      <h3 className="card-title">Paymet Method</h3>
                    </div>
                    <Formik>
                      <form onSubmit={formik.handleSubmit}>
                        <div className="card-body row">
                          <div className="col-xl-4 col-lg-4 col-md-4 ">
                            <label className="form-label">
                              <h5>Select payout method</h5>
                            </label>
                            <select
                              class="form-select form-select-lg mb-3"
                              aria-label=".form-select-lg example"
                              onChange={formik.handleChange}
                              name="method"
                              value={formik.values.method}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select payout method</option>
                              {Array.isArray(userDetails.payout_preferences) &&
                                userDetails.payout_preferences?.map((payout) => {
                                  return (
                                    <option value={payout}>{payout.charAt(0).toUpperCase() + payout.slice(1)}</option>
                                  );
                                })}
                            </select>
                            {formik.errors.method ? dispErrorMsg("Select payment method") : null}
                          </div>

                          {formik.values.method ? (
                            <div className="col-xl-7 col-lg-7 col-md-7 ">
                              <div className="row flex-column">
                                <div className="col-xl-12 lg-12 md-12 ">
                                  <div className="form-group">
                                    <label className="form-label">
                                      <h6>Enter label for this method (For Ex : MyBofaAcct)</h6>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter label"
                                      name="label"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.label}
                                    />
                                  </div>
                                  {formik.errors.label ? dispErrorMsg("Enter label") : null}
                                </div>
                                <div className="col-xl-12 lg-12 md-12 ">
                                  <div className="form-group">
                                    <label className="form-label">
                                      <h6>
                                        Enter{" "}
                                        {(formik.values.method || userDetails.payout_preferences[0])
                                          .charAt(0)
                                          .toUpperCase() +
                                          (formik.values.method || userDetails.payout_preferences[0]).substr(1)}{" "}
                                        Id {"(Email/ Phone Number/ Id)"}
                                      </h6>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder={
                                        (formik.values.method || userDetails.payout_preferences[0])
                                          .charAt(0)
                                          .toUpperCase() +
                                        (formik.values.method || userDetails.payout_preferences[0]).substr(1) +
                                        " Id " +
                                        "(Email/ Phone Number/ Id)"
                                      }
                                      name="method_details"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.method_details}
                                    />
                                  </div>
                                  {formik.errors.method_details ? dispErrorMsg("Enter details") : null}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-xl-12">
                            <button className="btn btn-primary mt-3 w-25" type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </Formik>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card mb-0">
                    <div className="card-header">
                      <h3 className="card-title">Payments</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive statement-card">
                        <table className="table card-table table-vcenter text-nowrap border-bottom">
                          <thead>
                            <tr>
                              <th>Offer ID</th>
                              <th>Platform</th>
                              <th>Details</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Transaction Id</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(paymentHistory) && paymentHistory.length > 0 ? (
                              <>
                                {paymentHistory.map((key, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-primary">{key.offer_id ? key.offer_id : "NF"}</td>
                                      <td className="text-center">{key.platform ? key.platform : "NF"}</td>
                                      <td className="fs-16">{key.details ? key.details : "NF"}</td>
                                      <td className="text-center">{key.amount ? key.amount : "NF"}</td>

                                      <td className="text-center">{key.date ? key.date : "NF"}</td>

                                      <td className="text-center">{key.transaction_id ? key.transaction_id : "NF"}</td>
                                      <td>
                                        <a
                                          className="fa fa-trash action-icons delete-btn text-center "
                                          // onClick={() => deleteOffers(key)}
                                        ></a>

                                        <a
                                          className="fa fa-eye action-icons eye-btn "
                                          // onClick={() => previewOffer(key)}
                                        ></a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <div className="card mb-0">
                    <div className="card-header">
                      <h3 className="card-title">Payment Methods </h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive statement-card">
                        <table className="table card-table table-vcenter text-nowrap border-bottom">
                          <thead>
                            <tr>
                              <th>Platform</th>
                              <th>Label</th>
                              <th>Details</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(paymentMethodList) && paymentMethodList.length > 0 ? (
                              <>
                                {paymentMethodList.map((key, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-primary">{key.platform ? key.platform : "NF"}</td>
                                      <td>{key.label ? key.label : "NF"}</td>
                                      <td className="fs-16">{key.details ? key.details : "NF"}</td>
                                      <td className="text-center">
                                        <a
                                          className="fa fa-trash action-icons delete-btn text-center hands-icon"
                                          onClick={() => deleteMethod(key.id)}
                                        ></a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PaymentInfo;
