import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DefaultLayout from "../containers/DefaultLayout";
// import DefaultLayoutWithoutLogin from "../containers/DefaultLayoutWithoutLogin";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import { GlobalContext } from "../context/GlobalContext";
import ResetPassword from "../pages/forgotPassword/ResetPassword";
import Offers from "../pages/offer/Offers";
import VerifyEmail from "../pages/signup/VerifyEmail";
import EditProfile from "../pages/seller/EditProfile";
import Dashboard from "../pages/seller/Dashboard";
import OfferList from "../pages/offer/OfferList";
import PreviewOffer from "../pages/offer/PreviewOffer";
import PaymentInfo from "../pages/payments/PaymentsInfo";
import Contact from "../pages/home/Contact";
import About from "../pages/home/About";
import Message from "../pages/message/Message";
import Faqs from "../pages/home/Faqs";
import ScrollToTop from "../containers/ScrollToTop";

function AllRoutes() {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;

  return isAuthentiCated ? (
    <DefaultLayout>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/offer" element={<Offers />} />
        <Route exact path="/offer/:id" element={<Offers />} />
        <Route exact path="/offer/preview/:id" element={<PreviewOffer />} />
        <Route exact path="/editprofile" element={<EditProfile />} />
        <Route exact path="/verifyemail/:token" element={<VerifyEmail />} />
        <Route exact path="/offerlist" element={<OfferList />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/payment" element={<PaymentInfo />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/message/:id" element={<Message />} />
        <Route exact path="/faqs" element={<Faqs />} />
        {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </DefaultLayout>
  ) : (
    <DefaultLayout>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        <Route exact path="/resetpassword/:token" element={<ResetPassword />} />
        <Route exact path="/signup" element={<Signup />} />
        {/* <Route exact path="/offers" element={<Offers />} /> */}
        <Route exact path="/verifyemail/:token" element={<VerifyEmail />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/faqs" element={<Faqs />} />
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </DefaultLayout>
  );
}
export default AllRoutes;
