
import util from "../util/util"
export default class Message {
    async list(activity){
        return util.sendApiRequest("/offer/message/list","POST",true,activity).then(
            (response) =>{
                return response
            },
            (error) =>{
                throw error
            }
        )
    }
    async send(payload){
        return util.sendApiRequest("/offer/message/post","POST",true,payload).then(
            (response) =>{
                return response
            },
            (error) =>{
                throw error
            }
        )
    }
}