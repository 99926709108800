import util from "../util/util";
import axios from "axios";

export default class UserService {
  signup(signupObj) {
    return util
      .sendApiRequest("/user/", "POST", true, signupObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  verifyEmail(obj) {
    return util
      .sendApiRequest("/user/verifyuser", "PUT", true, obj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  async addProfile(signupObj) {
    const token = localStorage.getItem("token") ? localStorage.getItem("token") : "no-token";
    const config = {
      headers: {
        content: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASEURL + "/user/addprofile", signupObj, config);
      if (response.err) {
        throw new Error(response.err);
      } else {
        localStorage.setItem("user", JSON.stringify(response.data.result));
        localStorage.setItem("token", response.data.token);
        return response;
      }
    } catch (err) {
      throw err;
    }
  }

  login(loginObj) {
    return util
      .sendApiRequest("/user/login", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            if (response.token) {
              localStorage.setItem("user", JSON.stringify(response.result));
              localStorage.setItem("token", response.token);
            }
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  otpLogin(loginObj) {
    let device_id = localStorage.getItem("device_id");
    loginObj.device_id = device_id;
    return util
      .sendApiRequest("/user/otplogin", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            if (response.token) {
              localStorage.setItem("user", JSON.stringify(response.data));
              localStorage.setItem("token", response.token);
            }
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  logout(loginObj) {
    let device_id = localStorage.getItem("device_id");
    let user = JSON.parse(localStorage.getItem("user"));
    loginObj.device_id = device_id;
    loginObj._id = user._id;
    return util
      .sendApiRequest("/user/logout", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }

  getUser(userId) {
    return util
      .sendApiRequest("/user/" + userId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  deleteUser(dataId) {
    return util
      .sendApiRequest("/user/" + dataId, "DELETE", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  listUser(payload) {
    return util
      .sendApiRequest("/user/list", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  sessionlist(payload) {
    return util
      .sendApiRequest("/user/sessionlist", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  signupActivationLink(email) {
    return util
      .sendApiRequest("/user/sendactivationlink", "POST", true, {
        email: email,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  sendOtp(obj) {
    return util
      .sendApiRequest("/user/forgot", "POST", true, obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  forgotpassword(obj) {
    return util
      .sendApiRequest("/user/forgotpassword", "POST", true, obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log("The Forgot password error is:", err);
        throw err;
      });
  }
  resetPassword(obj) {
    console.log(obj);
    return util
      .sendApiRequest("/user/resetpassword", "POST", true, obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  editSetting(obj) {
    return util
      .sendApiRequest("/user/setting", "POST", true, obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  editProfile(obj) {
    return util
      .sendApiRequest("/user", "PUT", true, obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  // async editProfile(signupObj) {
  //   const token = localStorage.getItem("token")
  //     ? localStorage.getItem("token")
  //     : "no-token";
  //   const config = {
  //     headers: {
  //       content: "multipart/form-data",
  //       Authorization: "Bearer " + token,
  //     },
  //   };
  //   try {
  //     const response = await axios.put(
  //       process.env.REACT_APP_API_BASEURL + "/user",
  //       signupObj,
  //       config
  //     );
  //     if (response.err) {
  //       throw new Error(response.err);
  //     } else {
  //       // localStorage.setItem("user", JSON.stringify(response.data.result));
  //       // localStorage.setItem("token", response.data.token);
  //       return response;
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // }
  getSearchData(payload) {
    return util
      .sendApiRequest("/user/getSearchData", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  getCountDataDateRange(id, startDate, endDate) {
    return util
      .sendApiRequest(`/user/sellerreport/${id}/${startDate}/${endDate}`, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  getCountData(id, days) {
    return util
      .sendApiRequest(`/user/sellerreport/${id}/` + days, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
