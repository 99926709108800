import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";

export default function SideBar() {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user;
  const navigate = useNavigate();
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const logoutHandler = () => {
    window.user = null;
    localStorage.clear();
    setUser(null);
    setIsAuthentiCated(false);
    navigate("/");
  };
  return (
    <div className="col-xl-3 col-lg-12 col-md-12">
      <div className="card">
        {/* <div className="card-header">
                  <h3 className="card-title">My Dashboard</h3>
                </div> */}
        <div className="card-body text-center item-user ">
          <div className="profile-pic">
            <div className="profile-pic-img mb-4">
              <span className="bg-success dots" data-bs-toggle="tooltip" data-bs-placement="top" title="online" />
              <img src="/assets/images/users/male/2.jpg" className="brround" alt="user" />
            </div>
            <a href="userprofile.html" className="text-dark">
              <h4 className="mt-3 mb-0 font-weight-semibold">
                {user.first_name} {user.last_name}
              </h4>
            </a>
          </div>
        </div>
        <aside className="app-sidebar doc-sidebar my-dash">
          <div className="app-sidebar__user clearfix">
            <ul className="side-menu">
              <li>
                <Link className="side-menu__item " to="/dashboard">
                  <i className="side-menu__icon fe fe-user" />
                  <span className="side-menu__label">Dashboard</span>
                </Link>
              </li>
              <li>
                <Link className="side-menu__item " to="/editprofile">
                  <i className="side-menu__icon fe fe-user" />
                  <span className="side-menu__label">Edit Profile</span>
                </Link>
              </li>
              <li>
                <Link to={"/offer"}>
                  <a className="side-menu__item">
                    <i className="side-menu__icon fe fe-codepen" />
                    <span className="side-menu__label">Create Offer</span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to={"/offerlist"}>
                  <a className="side-menu__item">
                    <i className="side-menu__icon fe fe-codepen" />
                    <span className="side-menu__label">Offers History</span>
                  </a>
                </Link>
              </li>

              {/* <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-heart" />
                  <span className="side-menu__label">My Favorite</span>
                </a>
              </li>
              <li className="slide">
                <a className="side-menu__item" data-bs-toggle="slide">
                  <i className="side-menu__icon fe fe-folder-plus" />
                  <span className="side-menu__label">Managed Items</span>
                  <i className="angle fa fa-angle-right" />
                </a>
                <ul className="slide-menu">
                  <li>
                    <a className="slide-item">
                      <i className="fa fa-caret-right me-2 text-muted" />{" "}
                      Managed Items
                    </a>
                  </li>
                  <li className="sub-slide">
                    <a
                      className="side-menu__item border-top-0 slide-item"
                      href="javascript:void(0);"
                      data-bs-toggle="sub-slide"
                    >
                      <span className="side-menu__label">
                        <i className="fa fa-caret-right me-2 text-muted" />{" "}
                        Submenu
                      </span>{" "}
                      <i className="sub-angle fa fa-angle-right" />
                    </a>
                    <ul className="child-sub-menu ">
                      <li>
                        <a className="slide-item" href="javascript:void(0);">
                          <i className="fa fa-caret-right me-2 text-muted" />{" "}
                          Level 1
                        </a>
                      </li>
                      <li>
                        <a className="slide-item" href="javascript:void(0);">
                          <i className="fa fa-caret-right me-2 text-muted" />{" "}
                          Level 2
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to="/payment">
                  <a className="side-menu__item">
                    <i className="side-menu__icon fe fe-credit-card" />
                    <span className="side-menu__label">Payout Preference</span>
                  </a>
                </Link>
              </li>
              <li className="hands-icon" onClick={logoutHandler}>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-credit-card" />
                  <span className="side-menu__label">Log out</span>
                </a>
              </li>
              {/* <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-shopping-cart" />
                  <span className="side-menu__label">Orders</span>
                </a>
              </li>
              <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-edit" />
                  <span className="side-menu__label">Statements</span>
                </a>
              </li>
              <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-compass" />
                  <span className="side-menu__label"> Safety Tips</span>
                </a>
              </li>
              <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-settings" />
                  <span className="side-menu__label">Settings</span>
                </a>
              </li>
              <li>
                <a className="side-menu__item">
                  <i className="side-menu__icon fe fe-power" />
                  <span className="side-menu__label">Logout</span>
                </a>
              </li> */}
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
}
