import util from "../util/util";

export default class BannerService {
    async info(){
        return util
        .sendApiRequest("/banner/list", "GET", true, {})
        .then(
          (response) => {
            return response;
          },
          (error) => {
            throw error;
          }
        );
    }
}