import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../../services/UserService";

const serv = new UserService();
export default function VerifyEmail() {
  const { token } = useParams();
  const [displayVerificationMsg, setDisplayVerificationMsg] = useState(false);

  useEffect(() => {
    verifyUserEmail(token);
  });

  const verifyUserEmail = async (token) => {
    if (token) {
      let obj = {};
      obj.account_verified_token = token;
      obj.is_active = true;
      try {
        const response = await serv.verifyEmail(obj);
        if (response.result == true) {
          setDisplayVerificationMsg(true);
        }
      } catch (err) {
        err = JSON.parse(err);
      }
    }
    let result = {};
  };

  return (
    <div className="page page-h" style={{ backgroundColor: "blueviolet" }}>
      <div className="construction">
        <div className="container text-center">
          <div className="row" style={{ marginTop: -100 }}>
            <div className="col-lg-8 col-xl-7 d-block mx-auto ">
              <div className="br-7 p-6 mb-0">
                {/* {displayVerificationMsg == true ? ( */}
                <div>
                  <h3 className="mb-2 text-center ">Thank You! </h3>
                  <p style={{ fontSize: 20 }}>
                    Your Email has been verified. Kindly go to the
                    <a href="/login" style={{ color: "black" }}>
                      &nbsp; login &nbsp;
                    </a>
                    page.
                  </p>
                </div>
                {/* ) : (
                  <div>
                    <h3 className="mb-2 text-center ">Thank You! </h3>
                    <p style={{ fontSize: 20 }}>
                      There was
                      <a href="/login" style={{ color: "black" }}>
                        &nbsp; login &nbsp;
                      </a>
                      page.
                    </p>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
