import React ,{ useState ,useEffect} from "react";
import { useNavigate} from "react-router-dom";
import OfferService from "../services/OfferService";

const offerServ = new OfferService()
export default function Footer() {
  const navigate = useNavigate()
  const [shipping,setShipping] = useState({});
  function navigateHandler(page){
    navigate(`/${page}`)
    window.scroll({
      top: 0,
      left : 0,
      behavior : "smooth"
    })
 
  }
  const fetchShippingDetails = async()=>{
    const shipping = await offerServ.getShipping();
    setShipping(prev => shipping.data[0]);
  }
  useEffect(()=>{
   fetchShippingDetails()
  },[])
  return (
    <section>
      <footer className="bg-dark text-white">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <h6 onClick={()=>{ navigateHandler("about") }} className="hands-icon">About</h6>
                <hr className="deep-purple  accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <p>
                  We are a dedicated team of ecommerce enthusiasts who have been
                  in the market for a long time. We started our journey with the
                  sole purpose of helping sellers make their online selling
                  journey smooth and easy. We take pride in our ability to buy
                  big brand products (Only brand new, authentic and factory
                  sealed products)
                </p>
                <ul className="list-unstyled list-inline mt-3">
                  <li className="list-inline-item">
                    <a className="btn ripple btn-floating btn-sm mx-1">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="btn ripple btn-floating btn-sm mx-1">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="btn ripple btn-floating btn-sm mx-1">
                      <i className="fa fa-google-plus" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="btn ripple btn-floating btn-sm mx-1">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-12">
                <h6>Our Services</h6>
                <hr className="deep-purple text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <ul className="list-unstyled mb-0">

                  {/* <li>
                    <a href="javascript:;">Our Team</a>
                  </li> */}
                  <li>
                    <p onClick={()=>{ 
                      navigateHandler("contact")
                  }} className="hands-icon my-0">Contact Us</p>
                  </li>
                  <li>
                    <p onClick={()=>{ navigateHandler("about")}} className="hands-icon  my-0">About </p>
                  </li>
                  <li>
                    <p onClick={()=>{ navigateHandler("faqs")}} className="hands-icon  my-0">FAQs</p>
                  </li>
                  {/* <li>
                    <a href="javascript:;">Services</a>
                  </li>
                  <li>
                    <a href="javascript:;">Blog</a>
                  </li>
                  <li>
                    <a href="javascript:;">Terms and Services</a>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-3 col-md-12">
                <h6 onClick={()=>{navigateHandler("contact")}} className="hands-icon">Contact</h6>
                <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <ul className="footer-conatct list-unstyled mb-0 contact-footer">
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-home me-3 text-white" /> 
                       {shipping.street_address ? shipping.street_address  : "22 S. Rock Creek StreetSan Carlos"} , {shipping.city ? shipping.city : null} <br />
                        {shipping.state ? shipping.state : null} , {shipping.country ? shipping.country : null}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-envelope me-3 text-white" />{" "}
                      <p className="mt-1 ">{shipping.email ? shipping.email : "zetoi333@gmail.com"} </p>
                    </a>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-phone me-3 text-white" /> + 01 234 567
                      88
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="fa fa-print me-3 text-white" /> + 01 234 567
                      89
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-4 col-md-12">
                {/* <h6>Subscribe</h6>
                <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <div className="clearfix" />
                <div className="input-group w-100">
                  <input
                    type="text"
                    className="form-control br-ts-3  br-bs-3 "
                    placeholder="Email"
                  />
                  <div className>
                    <button
                      type="button"
                      className="btn ripple  btn-primary br-te-3  br-be-3 br-0"
                    >
                      {" "}
                      Subscribe{" "}
                    </button>
                  </div>
                </div> */}
                <h6 className="mb-0">Payments</h6>
                <hr className="deep-purple  text-primary accent-2 mb-2 mt-3 d-inline-block mx-auto" />
                <div className="clearfix" />
                <ul className="footer-payments">
                  <li className="ps-0">
                    <a href="javascript:;">
                      <i className="fa fa-cc-amex" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <i className="fa fa-cc-visa" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <i className="fa fa-credit-card-alt" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <i className="fa fa-cc-mastercard" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <i className="fa fa-cc-paypal" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-dark text-white-50 p-0">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-12 col-sm-12 mt-3 mb-3 text-center">
                Copyright © 2022{" "}
                <a href="javascript:void(0);" className="fs-14 text-primary">
                  Sellzey
                </a>
                . Designed with <span className="fa fa-heart text-primary" /> by{" "}
                <a href="javascript:void(0);" className="text-primary">
                  {" "}
                  Spruko{" "}
                </a>{" "}
                All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}
