import React, { useEffect, useState, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { useFormik } from "formik";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import PageBanner from "../PageBanner";
import SideBar from "./SideBar";

const serv = new UserService();
const ValidateSchema = Yup.object({
  first_name: Yup.string().required("First Name is a required field"),
  last_name: Yup.string().required("Last name is a required field"),
  phone: Yup.string(),
  address_1: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  zipcode: Yup.string().required("Zipcode is required"),
});

const states = [
  "",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export default function EditProfile() {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [userDetails, setUserDetails] = useState({});
  const [userInfo, setUserInfo] = globalCtx.user;
  const [checkUserInfo, setCheckUserInfo] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    fetchProfile(userInfo.id);
    setCheckUserInfo(false);
  }, [checkUserInfo]);

  const fetchProfile = async (id) => {
    if (id) {
      const response = await serv.getUser(id);
      console.log(response);
      setUserDetails((prev) => {
        return { ...response, country: "USA" };
      });
      if (response) {
        setContentLoaded(true);
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response));
      }
    }
  };

  const isNumericString = (str) => {
    return /^\d+$/.test(str);
  };

  const onSubmit = async (details) => {
    let obj = details;
    if (userDetails.country) {
      formik.errors.country = null;
    }
    try {
      const response = await serv.editProfile(obj);
      setCheckUserInfo(true);
      toast(response.message);
    } catch (err) {
      toast(err);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userDetails,
    onSubmit,
    validationSchema: ValidateSchema,
  });

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };

  return (
    <div>
      <section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <PageBanner props="My Dashboard" />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <SideBar userDetails={userDetails} />
            <div className="col-xl-9 col-lg-12 col-md-12">
              {!contentLoaded ? (
                <div className="loadingImg">
                  <img src="/assets/images/loader.svg" className="text-center" />
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <div className="card mb-0">
                    <div className="card-header">
                      <h3 className="card-title">Edit Profile</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">First name*</label>
                            <input
                              type="text"
                              placeholder="First name"
                              onChange={formik.handleChange}
                              value={formik.values.first_name}
                              name="first_name"
                              className={
                                "form-control" +
                                (formik.touched.first_name && formik.errors.first_name ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.first_name && formik.errors.first_name
                              ? dispErrorMsg("First name is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Last name*</label>
                            <input
                              type="text"
                              placeholder="Last name"
                              onChange={formik.handleChange}
                              value={formik.values.last_name}
                              name="last_name"
                              className={
                                "form-control" +
                                (formik.touched.last_name && formik.errors.last_name ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.last_name && formik.errors.last_name
                              ? dispErrorMsg("Last name is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Company name (optional)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company"
                              onChange={formik.handleChange}
                              value={formik.values.company_name}
                              name="company_name"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Email address</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              disabled
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Phone number</label>
                            <input
                              type="text"
                              placeholder="Number"
                              onChange={formik.handleChange}
                              value={formik.values.phone}
                              name="phone"
                              className={
                                "form-control" + (formik.touched.phone && formik.errors.phone ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.phone && formik.errors.phone
                              ? dispErrorMsg("Phone number is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Address line 1</label>
                            <input
                              type="text"
                              placeholder="Home address"
                              onChange={formik.handleChange}
                              value={formik.values.address_1}
                              name="address_1"
                              className={
                                "form-control" +
                                (formik.touched.address_1 && formik.errors.address_1 ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.address_1 && formik.errors.address_1
                              ? dispErrorMsg("Address is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Address line 2 </label>
                            <input
                              type="text"
                              placeholder="Street Address"
                              onChange={formik.handleChange}
                              value={formik.values.address_2}
                              name="address_2"
                              className={
                                "form-control" +
                                (formik.touched.address_2 && formik.errors.address_2 ? " is-invalid" : "")
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">City*</label>
                            <input
                              type="text"
                              placeholder="City"
                              onChange={formik.handleChange}
                              value={formik.values.city}
                              name="city"
                              className={
                                "form-control" + (formik.touched.city && formik.errors.city ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.city && formik.errors.city ? dispErrorMsg("City is required") : null}
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6">
                          <div className="form-group">
                            <label className="form-label">State* </label>
                            <select
                              className="form-control"
                              style={{ width: "40%" }}
                              name="state"
                              onChange={formik.handleChange}
                              value={formik.values.state}
                            >
                              {states.map((st) => {
                                return <option value={st}>{st} </option>;
                              })}
                            </select>
                            {formik.errors.state ? dispErrorMsg("State is required") : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">Zipcode*</label>
                            <input
                              type="text"
                              placeholder="Zipcode"
                              onChange={formik.handleChange}
                              value={formik.values.zipcode}
                              name="zipcode"
                              className={
                                "form-control" + (formik.touched.zipcode && formik.errors.zipcode ? " is-invalid" : "")
                              }
                            />
                            {formik.touched.zipcode && formik.errors.zipcode
                              ? dispErrorMsg("Zipcode is required")
                              : null}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label className="form-label">Country*</label>

                            <select
                              className="form-control "
                              style={{ width: "20%" }}
                              value={formik.values.country}
                              name="country"
                              onChange={formik.handleChange}
                            >
                              <option value="USA">USA</option>
                            </select>
                            {formik.errors.country ? dispErrorMsg("Country is required") : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn ripple  btn-secondary">
                        Update profile
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
