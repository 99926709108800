import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageBanner from "../PageBanner";

const serv = new UserService();
const ValidateSchema = Yup.object({
  email: Yup.string().required("Email is a required field").email(),
});

function ForgotPassword() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [loginObj, setLoginObj] = new useState({
    email: "",
  });

  const notify = (msg) => {
    if (msg.error) toast("User does not exist!");
    else toast(msg.message);
  };

  const onSubmit = async (values) => {
    let obj = { ...values };
    try {
      const resp = await serv.forgotpassword(obj);
      notify(resp);
      if (resp?.result) {
        // navigate("/resetpassword", { state: { email: obj.email } })
      } else {
        setErrorMsg(resp);
      }
    } catch (err) {
      err = JSON.parse(err);
      setErrorMsg(err.err);
    }
  };

  const formik = useFormik({
    initialValues: loginObj,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      <section class="sptb">
        <PageBanner props="Forgot Password" />
        <div class="container user-auth-form-card">
          <div class="row">
            <div class="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
              <div class="single-page w-100 p-0">
                <div class="wrapper wrapper2">
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  <form
                    id="forgotpsd"
                    class="card-body"
                    onSubmit={formik.handleSubmit}
                  >
                    <div class="mail">
                      <input
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.email}
                        className={
                          "form-control" +
                          (formik.touched.email && formik.errors.email
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <label>E-mail</label>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="valid_feedbackMsg">
                        <p
                          style={{
                            color: "red",
                            marginTop: 10,
                            textAlign: "left",
                          }}
                          className="user-auth-msg"
                        >
                          {formik.errors.email}
                        </p>
                      </div>
                    ) : null}
                    {/* {formik.touched.email && formik.errors.email ? (
                      <div className="valid_feedbackMsg">
                        {formik.errors.email}
                      </div>
                    ) : null} */}
                    <div class="submit">
                      <button
                        class="btn ripple  btn-primary btn-block"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                    <div class="text-center text-dark mb-0">
                      Forget it,{" "}
                      <a>
                        {" "}
                        <Link to={"/login"}> send me back </Link>{" "}
                      </a>{" "}
                      to the sign in.
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  // return (
  //     <main className="w-100 clearfix socialMediaTheme">
  //         {/* login page Start*/}
  //         <div className="loginpage d-flex">
  //             <div className="loginForm_left">
  //                 <div className="loginFormLeftInner">
  //                     <div className="loginLogo">
  //                         <img src="/images/profile/logo-white.png" className="img-fluid" />
  //                     </div>
  //                     <div className="login_heading">
  //                         <h2>Forgot Password</h2>
  //                         <p>Lorem ipsum dolor sit amet, consectet</p>
  //                     </div>
  //                     <div className="logInform">
  //                         <form onSubmit={formik.handleSubmit}>
  //                             <div className="mb-3 mb-sm-4 commonform">
  //                                 <label htmlFor="username" className="form-label">Username</label>
  //                                 {/* <input type="text" className="form-control" id="username" defaultValue="michaelphilip@gmail.com" required /> */}
  //                                 <input
  //                                     className={
  //                                         "form-control" +
  //                                         (formik.touched.email && formik.errors.email
  //                                             ? " is-invalid"
  //                                             : "")
  //                                     }
  //                                     placeholder="john@mavefi.com"
  //                                     name="email"
  //                                     onChange={formik.handleChange}
  //                                     onBlur={formik.handleBlur}
  //                                     value={formik.values.email}
  //                                 />
  //                                 {formik.touched.email && formik.errors.email ? (
  //                                     <div className="valid_feedbackMsg">
  //                                         {formik.errors.email}
  //                                     </div>
  //                                 ) : null}
  //                             </div>
  //                             {errorMsg && (
  //                                 <div className="valid_feedbackMsg text-center">
  //                                     {errorMsg}
  //                                 </div>
  //                             )}
  //                             <div className="loginBtn">
  //                                 <button type="submit" href="javascript:void(0);" className="btn btnColor w-100">Send OTP</button>
  //                             </div>
  //                         </form>
  //                     </div>
  //                     {/* <div className="notA_member d-flex align-items-center">
  //                         <span />
  //                         <p className="mb-0">SignIn</p>
  //                         <span />
  //                     </div> */}
  //                     <div className="loginPara text-center mt-3">
  //                         <p><a href="javascript:void(0)" className="join" onClick={() => navigate("/login")}>SIgn In</a> to unlock the best of Trading view.</p>
  //                         {/* <p className="mt-3 mt-sm-4">By proceeding you agree to our <a href="javascript:void(0)" className="text-dark">Terms of Use</a> and confirm
  //                             you have read our <a href="javascript:void(0)" className="text-dark">Privacy and Cookie Statement</a>.</p> */}
  //                     </div>
  //                 </div>
  //             </div>
  //             <div className="loginRight_img">
  //                 <img src="/images/profile/login_img.png" className="img-fluid" />
  //             </div>
  //         </div>
  //         {/* login page End */}
  //     </main>

  // )
}
export default ForgotPassword;
