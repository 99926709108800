import PageBanner from "../PageBanner";
import OfferService from "../../services/OfferService";
import MessageService from "../../services/MessageService";
import UserService from "../../services/UserService";

function Faqs() {
  return (
    <div>
      <section>
        <PageBanner props="FAQ" />
      </section>
      <div>
        {/*/Section*/}
        {/*Section*/}
        <section className="sptb">
          <div className="container">
            <div aria-multiselectable="true" className="accordion1" id="accordion" role="tablist">
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  1. What's it all about?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseOne" role="tabpanel">
                  <div className="accordion-body">
                    Sellzey is a service that buys your BRAND NEW, FACTORY SEALED, AUTHENTIC products in bulk from
                    various categories mainly electronics, Home Improvement goods, Tools categories. We provide our
                    customers with an easy way to sell their unwanted electronics/Home Improvement goods/Tools without
                    the hassle and risks associated with auctions/paying 3rd party platform high fee/customer returns.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                  2. Why Should I trust Sellzey?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseTwo" role="tabpanel">
                  <div className="accordion-body">
                    We understand that you might feel uneasy about sending us your item(s). However, here at Sellzey,
                    nothing matters more to us than providing a phenomenal experience while creating customer value and
                    building a relationship of trust with our customers. Rest assured that Sellzey understands and
                    acknowledges the trust our customers put into our business. We continuously strive to ensure that we
                    deliver to our customers securely and in a timely manner. These actions are taken understanding the
                    time invested into the development, organization and entrepreneurship of this website is focused to
                    ensure it is both user friendly and offers top dollar for your goods, it would be a bad business
                    decision on our part not to honor our promise and your trust.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                  3. How and when will I get paid?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseThree" role="tabpanel">
                  <div className="accordion-body">
                    We currently offer PayPal method of payment.Once your reputation grows our team will enable
                    zelle/venmo/cashapp payment modes enabled for your profile You'll be asked to indicate your
                    preference when you check out. Your payment will be sent to you after we receive and inspect your
                    item(s), which typically takes 1 to 2 business days. After your item(s) have been inspected, payment
                    is processed within 24 hours of approval. We do offer an expedited option that guarantees 1 business
                    day processing.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                  4. What should I do if I haven't received my payment?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseFour" role="tabpanel">
                  <div className="accordion-body">
                    Once we have inspected your item(s), we typically issue the payment within 24 hours of approval. For
                    each payment, you will receive an email from us notifying you of the payment along with the
                    confirmation number. Please be aware that the time it takes for you to receive your payment will
                    differ depending on your preferred method of getting paid.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                  5. What if I put my wrong personal information for payment or wrong payment type?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseFive" role="tabpanel">
                  <div className="accordion-body">
                    If you want to change your payment type, you will have to contact us via email, for one of our
                    representatives to make the changes in the backend. These changes will reflect under the “Remarks”
                    notes on your offer page. If you have the wrong address, or any other incorrect personal information
                    associated with your account, you cannot modify that information for an offer that has already been
                    submitted. This is done for security reasons. In the event the change requested can be completed you
                    will be able to see these changes on your offer page.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                  6. Does sellzey accept items from outside of the United States?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseSix" role="tabpanel">
                  <div className="accordion-body">
                    No. At the present, we do not accept shipments from outside of the United States.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                  7. Is there a limit on the number of items I can sell?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseSeven" role="tabpanel">
                  <div className="accordion-body">
                    No. There is no limit, you can sell as many items as you want. All bulk sales are subject to final
                    approval and may be cancelled for any reason whatsoever as deemed necessary.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseEight">
                  8. What if my products delivered are not authentic?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseEight" role="tabpanel">
                  <div className="accordion-body">
                    Our specialized team in validating whether products are authentic or not.If your products have a
                    "red flag" against it during the Quality Assessment process, we'll be unable to pay for it and all
                    products will be returned to you.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseNine">
                  9. Why should I sign up for an account?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseNine" role="tabpanel">
                  <div className="accordion-body">
                    Signing up allows you to track your offers. You will also have your information saved with us and
                    can checkout much faster the next time you choose to sell your goods to us.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseTen">
                  10. I haven't received a Confirmation Email. What Should I do?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseTen" role="tabpanel">
                  <div className="accordion-body">
                    Please ensure that you have entered a valid email address, and that the confirmation email has not
                    been sent to your Junk or Spam Mail Folder. Some email programs may mistakenly misroute your
                    confirmation email. We recommend adding sellzey to your list of Safe Senders to ensure proper email
                    delivery.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseEleven">
                  11. What if I put my wrong personal information for payment or wrong payment type?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseEleven" role="tabpanel">
                  <div className="accordion-body">
                    If you want to change your payment type, you will have to contact us via email for one of our
                    representatives to make the changes in the backend. These changes will reflect under the
                    “Adjustment” notes on your offer page. If you have the wrong address, or any other incorrect
                    personal information associated with your account, you cannot modify that information for an offer
                    that has already been submitted. This is done for security reasons. You will have to contact us to
                    modify the information for an offer that has already been submitted. Once again, you will be able to
                    see these changes on your offer page.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseTweleve">
                  12.How long do I have to ship my item to you?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseTweleve" role="tabpanel">
                  <div className="accordion-body">
                    The price sellzey has quoted you is valid for ten (10) days. If we receive your item AFTER ten (10)
                    days, sellzey shall re-evaluate your item and price it at the CURRENT market value.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseThirteen">
                  13. How do I ship items to Sellzey?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseThirteen" role="tabpanel">
                  <div className="accordion-body">
                    Once your offer is approved , you'll have to print offer packing slip , place the packing slip
                    inside the package and carefully package the box.We prefer you to ship through UPS or FedEx. Once
                    your package is ready to ship, please drop it off at the nearest FedEx or UPS location. Once your
                    offer is shipped you will have to update tracking information on offer so that we keep an eye on
                    your package and process funds as soon as validated.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseFourteen">
                  14. What if my item is damaged in shipping?
                </div>
                <div className="collapse" data-bs-parent="#accordion" id="collapseFourteen" role="tabpanel">
                  <div className="accordion-body">
                    It is your responsibility to make sure that your item(s) are fully secure when you ship them. if the
                    box is secure and does not look as though anything happened during transit and the item inside was
                    not secure, we will return the damaged products to your address at no cost.
                  </div>
                </div>
              </div>
            </div>
            {/* accordion */}
          </div>
        </section>
      </div>
    </div>
  );
}
export default Faqs;
