import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import { useFormik } from "formik";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import PageBanner from "../PageBanner";
import SideBar from "./SideBar";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
const serv = new UserService();
const ValidateSchema = Yup.object({
  first_name: Yup.string().required("First Name is a required field"),
  last_name: Yup.string().required("Last name is a required field"),
  phone: Yup.string().required("Phone number is required"),
  address_1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
});

export default function EditProfile() {
  const globalCtx = useContext(GlobalContext);
  const [contentLoaded, setContentLoaded] = useState(true);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const [userDetails, setUserDetails] = useState([]);
  const [userInfo, setUserInfo] = globalCtx.user;
  const [checkUserInfo, setCheckUserInfo] = useState(false);
  const [filterDay, setFilterDay] = useState(1);
  const [cardResponse, setCardResponse] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchProfile(userInfo.id);
    setCheckUserInfo(false);
  }, [checkUserInfo]);

  const fetchProfile = async (id) => {
    if (id) {
      const response = await serv.getUser(id);
      setUserDetails(response);
      if (response) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response));
      }
    }
  };

  const onSubmit = async (details) => {
    let obj = details;
    try {
      const response = await serv.editProfile(obj);
      setCheckUserInfo(true);
      toast(response.message);
    } catch (err) {
      toast(err);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userDetails,
    onSubmit,
    validationSchema: ValidateSchema,
  });

  const dispErrorMsg = (err) => {
    return (
      <div className="valid_feedbackMsg">
        <p
          style={{
            color: "red",
            marginTop: 10,
            textAlign: "left",
          }}
          className="user-auth-msg"
        >
          {err}
        </p>
      </div>
    );
  };
  useEffect(() => {
    getCardData();
  }, [filterDay, startDate, endDate]);

  const getCardData = async () => {
    try {
      if (filterDay == "custom") {
        let response = await serv.getCountDataDateRange(
          userInfo.id,
          startDate ? moment(startDate).tz("America/New_York").format("YYYY-MM-DD") : "2000-01-01",
          endDate ? moment(endDate).tz("America/New_York").format("YYYY-MM-DD") : "2060-12-31"
        );
        setCardResponse(response);
      } else if (filterDay == "this_year") {
        let Difference_In_Days = moment().dayOfYear();
        let response = await serv.getCountData(userInfo.id, Difference_In_Days);
        setCardResponse(response);
      } else {
        let response = await serv.getCountData(userInfo.id, filterDay);
        setCardResponse(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <PageBanner props="My Dashboard" />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <SideBar userDetails={userDetails} />
            <div className="col-xl-9 col-lg-12 col-md-12">
              {!contentLoaded ? (
                <div className="loadingImg">
                  <img src="/assets/images/loader.svg" className="text-center" />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between mx-1  row ">
                    <h2 className="title-container-dashbrd my-2 col-md-6 col-12">
                      <i className="fa fa-home me-2"></i>
                      <span>Dashboard</span>
                    </h2>
                    {filterDay == "custom" && (
                      <div className="col-md-3 col-6 mt-3">
                        <DatePicker
                          placeholderText="Start Date"
                          onChange={(date) => setStartDate(date)}
                          selected={startDate}
                          maxDate={endDate}
                        />
                      </div>
                    )}
                    <div className={"mb-2 col-md-3 col-12" + (filterDay == "custom" ? " " : " filterInp")}>
                      {filterDay != "custom" ? (
                        <div className="d-flex justify-content-between">
                          <select
                            className="form-control selectInp"
                            onChange={(e) => {
                              setFilterDay(e.target.value);
                            }}
                          >
                            <option value="1">Today </option>
                            <option value="7">7 Days</option>
                            <option value="30">30 Days</option>
                            <option value="60">60 Days</option>
                            <option value="90">90 Days</option>
                            <option value="this_year">This Year</option>
                            <option value="custom">Custom</option>
                          </select>
                          <i class="fa fa-filter m-auto mx-2"></i>
                        </div>
                      ) : (
                        <div className="d-flex mt-3">
                          <DatePicker
                            placeholderText="End Date"
                            onChange={(date) => setEndDate(date)}
                            selected={endDate}
                            minDate={startDate}
                          />
                          <div className="m-auto">
                            <i class="width10 fa fa-close m-auto mx-2" onClick={() => setFilterDay("option")}></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=not-draft&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Total Offers Count </p>
                            <p className="text-secondary">{cardResponse?.total_offers?.cnt}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=closed&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Total Payouts Amount</p>
                            <p className="text-secondary">
                              $
                              {cardResponse?.payouts_for_closed_status?.amount
                                ? cardResponse?.payouts_for_closed_status?.amount
                                : 0}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link
                        to={`/offerlist?s=unpaid||shipped,approved,revised,delivered,sent&d=${filterDay}&sd=${startDate}&ed=${endDate}`}
                      >
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Total Pending Payouts Amount</p>
                            <p className="text-secondary">
                              ${cardResponse?.pending_payouts?.amount ? cardResponse?.pending_payouts?.amount : 0}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=approved&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>
                              Pending Shipment Offers Count and Amount
                            </p>
                            <p className="text-secondary">
                              {cardResponse?.shipment_offers_count_and_amount?.cnt}- $
                              {cardResponse?.shipment_offers_count_and_amount?.amount
                                ? cardResponse?.shipment_offers_count_and_amount?.amount
                                : 0}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=approved,shipped&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Paid & Not Dispatched Count</p>
                            <p className="text-secondary">{cardResponse?.paid_and_not_dispatched_count?.cnt}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=shipped&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Shipped Offers count</p>
                            <p className="text-secondary">{cardResponse?.shipped_offers_count?.cnt}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=sent&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Sent Offers Count</p>
                            <p className="text-secondary">{cardResponse?.sent_offers_count?.cnt}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12  p-3 ">
                      <Link to={`/offerlist?s=draft&d=${filterDay}&sd=${startDate}&ed=${endDate}`}>
                        <div className="border rounded white-bg p-2">
                          <div className="pt-3">
                            <p style={{ fontWeight: "500", fontSize: "0.875rem" }}>Draft Offers Count</p>
                            <p className="text-secondary">
                              {cardResponse?.draft_offer_count?.cnt ? cardResponse?.draft_offer_count?.cnt : 0}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  return (
    <div>
      <section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <PageBanner props="My Dashboard" />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <SideBar userDetails={userDetails} />
            <div className="col-xl-9 col-lg-12 col-md-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="card mb-0">
                  <div className="card-header">
                    <p className="card-title">Edit Profile</p>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            placeholder="First name"
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                            name="first_name"
                            className={
                              "form-control" +
                              (formik.touched.first_name && formik.errors.first_name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.first_name && formik.errors.first_name
                            ? dispErrorMsg("First name is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            placeholder="Last name"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            name="last_name"
                            className={
                              "form-control" +
                              (formik.touched.last_name && formik.errors.last_name ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.last_name && formik.errors.last_name
                            ? dispErrorMsg("Last name is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Email address</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            disabled
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Phone Number</label>
                          <input
                            type="number"
                            placeholder="Number"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            name="phone"
                            className={
                              "form-control" + (formik.touched.phone && formik.errors.phone ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.phone && formik.errors.phone
                            ? dispErrorMsg("Phone number is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label">Address</label>
                          <input
                            type="text"
                            placeholder="Home address"
                            onChange={formik.handleChange}
                            value={formik.values.address_1}
                            name="address_1"
                            className={
                              "form-control" +
                              (formik.touched.address_1 && formik.errors.address_1 ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.address_1 && formik.errors.address_1
                            ? dispErrorMsg("Address is required")
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            placeholder="City"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            name="city"
                            className={
                              "form-control" + (formik.touched.city && formik.errors.city ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.city && formik.errors.city ? dispErrorMsg("City is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">State </label>
                          <input
                            type="text"
                            placeholder="State"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                            name="state"
                            className={
                              "form-control" + (formik.touched.state && formik.errors.state ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.state && formik.errors.state ? dispErrorMsg("State is required") : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Country</label>
                          <input
                            type="text"
                            placeholder="Country"
                            onChange={formik.handleChange}
                            value={formik.values.country}
                            name="country"
                            className={
                              "form-control" + (formik.touched.country && formik.errors.country ? " is-invalid" : "")
                            }
                          />
                          {formik.touched.country && formik.errors.country ? dispErrorMsg("Country is required") : null}
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Company Name (Optional)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                            onChange={formik.handleChange}
                            value={formik.values.company_name}
                            name="company_name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn ripple  btn-secondary">
                      Update Profile
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import AddEditOffers from "../offer/AddEditOffer";
// import OfferList from "../offer/OfferList";
// import PageBanner from "../PageBanner";
// import LeftSideNavbar from "./LeftSideNavbar";

// export default function Dashboard() {
//   const [checkOfferList, setCheckOfferList] = useState(false);
//   const [checkAddEditOffers, setCheckAddEditOffers] = useState(false);

//   useEffect(() => {}, []);
//   const changeTabs = async (key) => {
//     console.log(key);
//     setCheckOfferList(false);
//     setCheckAddEditOffers(false);

//     if (key == "offer") {
//       setCheckOfferList(true);
//     }
//     if (key == "offer-add-edit") {
//       setCheckAddEditOffers(true);
//     }
//   };

//   const getOfferId = async () => {
//     console.log("R+++");
//   };

//   return (
//     <section className="sptb">
//       <PageBanner props="My Dashboard" />
//       <div className="wrapper">
//         <div className="row" style={{ padding: 30 }}>
//           <div className="col-xl-3 col-lg-12 col-md-12">
//             <div className="card">
//               <aside className="app-sidebar doc-sidebar my-dash">
//                 <div className="app-sidebar__user clearfix">
//                   <ul className="side-menu">
//                     <li>
//                       <a className="side-menu__item ">
//                         <i className="side-menu__icon fe fe-user" />
//                         <span className="side-menu__label">Edit Profile</span>
//                       </a>
//                     </li>
//                     <li onClick={() => changeTabs("offer")}>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-codepen" />
//                         <span className="side-menu__label">Offer List</span>
//                       </a>
//                     </li>
//                     <li onClick={() => changeTabs("offer-add-edit")}>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-codepen" />
//                         <span className="side-menu__label">Add Offers</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-heart" />
//                         <span className="side-menu__label">My Favorite</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-credit-card" />
//                         <span className="side-menu__label">Payments</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-shopping-cart" />
//                         <span className="side-menu__label">Orders</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-edit" />
//                         <span className="side-menu__label">Statements</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-compass" />
//                         <span className="side-menu__label"> Safety Tips</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-settings" />
//                         <span className="side-menu__label">Settings</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a className="side-menu__item">
//                         <i className="side-menu__icon fe fe-power" />
//                         <span className="side-menu__label">Logout</span>
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </aside>
//             </div>
//           </div>
//           <div className="col-xl-9 col-lg-12 col-md-12">
//             <div className="card">
//               {checkOfferList ? (
//                 <div>
//                   <OfferList />
//                 </div>
//               ) : (
//                 ""
//               )}
//               {checkAddEditOffers ? (
//                 <div>
//                   <AddEditOffers />
//                 </div>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
