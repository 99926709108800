import axios from "axios";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export default {
  async sendApiRequest(url, method, setauth, body) {
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };
    if (method === "DELETE") {
      // delete requestOptions.body;
    }
    if (method === "GET") {
      delete requestOptions.body;
    }

    if (setauth === true) {
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : "no-token";
      requestOptions.headers["Authorization"] = "Bearer " + token;
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_BASEURL + url, requestOptions);
      let body = await response.text();
      if (response.status != 200) {
        throw body;
      }
      const data = body.includes("{") ? JSON.parse(body) : body;
      return data;
    } catch (e) {
      throw e;
    }
  },

  async sendApiRequestWithFiles(url, method, setauth, body) {
    const config = {
      url: process.env.REACT_APP_API_BASEURL + url,
      method: method,
      headers: {
        content: "multipart/form-data",
      },
      data: body,
    };
    if (method === "DELETE") {
      delete config.body;
    }
    if (method === "GET") {
      delete config.body;
    }
    if (setauth === true) {
      let token = localStorage.getItem("token") ? localStorage.getItem("token") : "no-token";
      config.headers["Authorization"] = "Bearer " + token;
    }
    try {
      const response = await axios(config);
      if (response.data.err) {
        throw new Error(response.data.err);
      } else {
        return response.data;
      }
    } catch (err) {
      throw err;
    }
  },
  async dataUrlToFile(url, fileName) {
    const [mediaType, data] = url.split(",");

    const mime = mediaType.match(/:(.*?);/)?.[0];
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();

    return new File([buffer], fileName, { type: mime });
  },
  formatDate(date) {
    return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-");
  },
  formatDateTime(date) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
    );
  },
  formatDateUTC(date) {
    let offset = date.getTimezoneOffset();
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-");
  },
  formatDateTimeUTC(date) {
    let offset = date.getTimezoneOffset();
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return (
      [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-") +
      " " +
      [padTo2Digits(d.getHours()), padTo2Digits(d.getMinutes()), padTo2Digits(d.getSeconds())].join(":")
    );
  },
  formatDateAsREACT_APP_MOMENT_TIME_ZONE(date) {
    const dt = new Date();
    const utcDate = new Date(dt.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(dt.toLocaleString("en-US", { timeZone: process.env.REACT_APP_MOMENT_TIME_ZONE }));
    let offset = (utcDate.getTime() - tzDate.getTime()) / 6e4;
    let d = new Date(date.getTime() + offset * 60 * 1000);
    return [d.getFullYear(), padTo2Digits(d.getMonth() + 1), padTo2Digits(d.getDate())].join("-");
  },
};
