import React from "react";
import { Link } from "react-router-dom";
function Banner({ banners }) {
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        {/* <ol className="carousel-indicators">
          {banners.length > 0 &&
            banners.map((banner, i) => {
              return <li data-target="#carouselExampleIndicators" data-slide-to={i} className={i == 0 && " active"} />;
            })}
        </ol> */}
        <div className="carousel-inner">
          {banners.length > 0 &&
            banners.map((banner, i) => {
              let bgImage = {};
              if (banner.primary_image) {
                bgImage.backgroundImage = `url(${banner.primary_image})`;
              }
              return (
                <div className={"carousel-item " + (i == 0 && " active")}>
                  <section className={"banner-1 cover-image  section-bg-background-color"} style={bgImage}>
                    <div className="sptb-1">
                      <div className="header-text text mb-0">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="text-white mt-xl-9 mb-5 mb-lg-0">
                                <h1 className="mb-5 display-4 font-weight-light">
                                  {banner.title ? (
                                    banner.title
                                  ) : (
                                    <span>
                                      The <span className="font-weight-semibold">Best Designs </span>
                                      <br />
                                      For Our Marketplace
                                    </span>
                                  )}
                                </h1>
                                <p className="fs-18 mb-4">
                                  {/* If you are going to use a passage of Lorem Ipsum, you need
                      to be sure there isn't anything embarrassing hidden in the
                      middle of text. */}
                                  {banner.subtitle ? (
                                    banner.subtitle
                                  ) : (
                                    <span>
                                      If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                                      anything embarrassing hidden in the middle of text'.
                                    </span>
                                  )}
                                </p>
                                {/* <Link to={"/offer"} className="btn ripple  btn-secondary me-2">
                                  Start Selling <i className="fa fa-chevron-circle-right ms-1 mt-1 fs-13" />
                                </Link> */}
                              </div>
                            </div>
                            {/* <div className="col-lg-6">
                              <img className="d-block w-100" src={banner.primary_image} alt="First slide" />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            })}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </>
  );
}
// function Banner({ bannerImg }) {
//   return (
//     <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
//       <ol className="carousel-indicators">
//         <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
//         <li data-target="#carouselExampleIndicators" data-slide-to={1} />
//         <li data-target="#carouselExampleIndicators" data-slide-to={2} />
//       </ol>
//       <div className="carousel-inner">
//         {bannerImg.map((v, i) => {
//           return (
//             <div className={"carousel-item " + (i == 0 && " active")}>
//               <img className="d-block w-100" src={v} alt="First slide" />
//             </div>
//           );
//         })}
//       </div>
//       <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
//         <span className="carousel-control-prev-icon" aria-hidden="true" />
//         <span className="sr-only">Previous</span>
//       </a>
//       <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
//         <span className="carousel-control-next-icon" aria-hidden="true" />
//         <span className="sr-only">Next</span>
//       </a>
//     </div>
//   );
// }

export default Banner;
