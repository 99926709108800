import PageBanner from "../PageBanner";
import TestimonalServices from "../../services/TestimonialService";
import { Rating } from "react-simple-star-rating";
import OwlCarousel from "react-owl-carousel";
import React, { useState, useEffect } from "react";
const TestimonialServ = new TestimonalServices();
function About() {
  const [testimonialList, setTestimonialList] = useState([]);
  async function getTestimonialListApi() {
    try {
      let response = await TestimonialServ.listApi();
      console.log("response of all list is ", response);
      setTestimonialList((prev) => response.data.rows);
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    getTestimonialListApi();
  }, []);
  return (
    <div>
      <section>
        <PageBanner props="About Us" />
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="sptb bg-white col-12">
              <h2 className="text-center fs-2">Who We Are ?</h2>
              <article className="mt-3 fs-5">
                At Sellzey, we have been revolutionizing the ecommerce market for years. We understand that selling
                online can be a daunting task, which is why we have made it our mission to help sellers succeed.Our team
                consists of experts who are dedicated to ensuring that every transaction is smooth and hassle-free.We
                take pride in our ability to buy big brand products (Only brand new, authentic and factory sealed
                products). With our expertise and experience, we ensure that our sellers receive the best value for
                their products.
              </article>
            </div>
            <div className="sptb bg-primary-subtle mt-3 col-12">
              <h2 className="text-center fs-2">Our Story</h2>
              <article className="mt-3 fs-5">
                We are a dedicated team of ecommerce enthusiasts who have been in the market for a long time. We started
                our journey with the sole purpose of helping sellers make their online selling journey smooth and easy.
                Over the years, we have gained extensive experience and knowledge in the ecommerce industry. Our team is
                always striving to provide the best services to our sellers.
              </article>
            </div>
            <div className="sptb bg-white mt-3  col-12">
              <h2 className="text-center fs-2">Our Mission</h2>
              <article className="mt-3 fs-5">
                Our mission is to simplify the selling process for online sellers and make it hassle-free. We aim to
                provide a platform that makes selling products easy, fast, and secure. Our services are designed to
                cater to sellers of all sizes.
              </article>
            </div>
            <div className="sptb mt-3 col-12">
              <h2 className="text-center mb-3 fs-2">What We Do ?</h2>
              <article className="mt-3 fs-5">
                We buy all big brand products and help sellers to sell us directly through our platform.Don't let
                third-party platforms eat into your profits - sell to us and keep what's yours. Our team of experts is
                always available to answer any questions and provide guidance to our sellers.
              </article>
            </div>
            <div className="sptb bg-white col-12">
              <h2 className="text-center mb-3 fs-2">Why Choose Us ?</h2>
              <article className="mt-2 fs-5">
                We have a proven track record of helping sellers achieve success in the ecommerce market. Our team of
                experts has years of experience and is committed to providing the best service to our sellers. Selling
                doesn't have to be hard - our platform simplifies the process and ensures fast payments , high value to
                your products , and makes bulk selling process easy.
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="sptb bg-white">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>How It Works?</h2>
            <p>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 mt-xl-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="widgets-cards mb-5">
                    <div className="d-flex">
                      <div className="widgets-cards-icons">
                        <div className="wrp counter-icon1 me-5 bg-primary box-primary-shadow">
                          <i className="las la-search-plus la-4x" />
                        </div>
                      </div>
                      <div className="widgets-cards-data">
                        <div className="text-wrapper">
                          <h4>
                            <a
                              href="javascript:void(0);"
                              className="text-dark fs-22"
                            >
                              Select Your Theme
                            </a>
                          </h4>
                          <p className="text-muted mt-2 mb-0">
                            Neque porro quisquam est, qui dolorem ipsum quia
                            dolor sit amet, consectetur, adipisci velit, sed
                            quia non numquam
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="widgets-cards mb-5">
                    <div className="d-flex">
                      <div className="widgets-cards-icons">
                        <div className="wrp counter-icon1 me-5 bg-secondary box-secondary-shadow">
                          <i className="las la-cart-plus la-4x" />
                        </div>
                      </div>
                      <div className="widgets-cards-data">
                        <div className="text-wrapper">
                          <h4>
                            <a
                              href="javascript:void(0);"
                              className="fs-22 text-dark"
                            >
                              Add To Cart
                            </a>
                          </h4>
                          <p className="text-muted mt-2 mb-0">
                            Neque porro quisquam est, qui dolorem ipsum quia
                            dolor sit amet, consectetur, adipisci velit, sed
                            quia non numquam
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="widgets-cards">
                    <div className="d-flex">
                      <div className="widgets-cards-icons">
                        <div className="wrp counter-icon1 me-5 bg-success box-success-shadow">
                          <i className="las la-money-bill la-4x" />
                        </div>
                      </div>
                      <div className="widgets-cards-data">
                        <div className="text-wrapper">
                          <h4>
                            <a
                              href="javascript:void(0);"
                              className="fs-22 text-dark"
                            >
                              Purchase Your Theme
                            </a>
                          </h4>
                          <p className="text-muted mt-2 mb-0">
                            Neque porro quisquam est, qui dolorem ipsum quia
                            dolor sit amet, consectetur, adipisci velit, sed
                            quia non numquam
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mrt-sm-2">
              <div className="text-center">
                <img
                  src="/assets/images/png/banner/b8.png"
                  alt="img"
                  className
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          className="cover-image about-widget sptb bg-background-color"
          data-bs-image-src="/assets/images/banners/banner3.jpg"
        >
          <div className="content-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h2 className="mb-2 font-weight-normal">
                  Ready to Join Our Marketplace
                </h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
                <div className="mt-5">
                  <a
                    href="posts.html"
                    className="btn ripple  btn-lg btn-secondary"
                  >
                    Join Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>Why Choose Us?</h2>
            <p>
              Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
            </p>
          </div>
          <div className="row ">
            <div className="col-md-6 col-lg-4 features">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-primary box-primary-shadow br-3 text-center">
                          <i className="fe fe-credit-card" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>Secure Payment</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 features">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-secondary box-secondary-shadow br-3 text-center">
                          <i className="fe fe-airplay" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>Quality Templates</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 features">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-success box-success-shadow br-3 text-center">
                          <i className="fe fe-dollar-sign" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>100% Money Back</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 features">
              <div className="card mb-lg-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-info box-info-shadow br-3 text-center">
                          <i className="fe fe-database" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>User Friendly</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 features">
              <div className="card mb-lg-0 mb-md-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-warning box-warning-shadow br-3 text-center">
                          <i className="fe fe-thumbs-up" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>100% Responsive Rate</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 features">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-2 col-lg-3">
                      <div className="text-white  mb-md-0 mb-4">
                        <div className="fa-stack fa-lg fea-icon bg-danger box-danger-shadow br-3 text-center">
                          <i className="fe fe-headphones" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-9">
                      <div className="mt-1">
                        <h4 className>24/7 Support</h4>
                        <p className="mb-0 text-muted">
                          Our being able to do what we like best, every pleasure
                          is to be welcomed and every pain..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="about-1 cover-image sptb section-bg-background-color1">
          <div className="content-text mb-0 text-white info">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-3 col-md-6">
                  <div className="counter-status md-mb-0">
                    <div className="counter-icon">
                      <i className="fe fe-codepen" />
                    </div>
                    <h5 className="font-weight-normal">Total Items</h5>
                    <h2 className="counter mb-0">2569</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="counter-status status-1 md-mb-0">
                    <div className="counter-icon text-warning">
                      <i className="fe fe-shopping-cart" />
                    </div>
                    <h5 className="font-weight-normal">Total Sales</h5>
                    <h2 className="counter mb-0">1765</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="counter-status status md-mb-0">
                    <div className="counter-icon text-primary">
                      <i className="fe fe-users" />
                    </div>
                    <h5 className="font-weight-normal">Total Members</h5>
                    <h2 className="counter mb-0">846</h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="counter-status status">
                    <div className="counter-icon text-success">
                      <i className="icon icon-emotsmile" />
                    </div>
                    <h5 className="font-weight-normal">Happy Customers</h5>
                    <h2 className="counter mb-0">7253</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="sptb bg-white">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>Testimonials</h2>
            <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </div>
          <div className="row">
            {Array.isArray(testimonialList) && testimonialList.length > 0 && (
              <OwlCarousel nav>
                {testimonialList.map((tlist) => {
                  return (
                    <>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="card mb-xl-0">
                          <div className="card-body">
                            <div className="team-section text-center">
                              <div className="team-img d-flex justify-content-center">
                                <img
                                  // src="/assets/images/users/male/1.jpg"
                                  src={tlist?.image ? tlist?.image : "/assets/images/users/male/1.jpg"}
                                  className="img-thumbnail rounded-circle alt="
                                  alt="img"
                                />
                              </div>
                              <h3 className="dark-grey-text mt-4">{tlist.name}</h3>
                              <h6 className="text-default font-weight-normal mb-3">{tlist.designation}</h6>
                              <Rating
                                onClick={(event) => event.preventDefault()}
                                initialValue={tlist.ratings}
                                allowHover={false}
                                allowFraction={true}
                                readonly={true}
                                size={15}
                              />
                              <p className="font-weight-normal text-muted mb-0">
                                <i className="fa fa-quote-left pe-2" />
                                {tlist.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </OwlCarousel>
            )}
            {/* <div className="col-lg-4 col-md-12">
              <div className="card mb-xl-0">
                <div className="card-body">
                  <div className="team-section text-center">
                    <div className="team-img">
                      <img
                        src="/assets/images/users/male/1.jpg"
                        className="img-thumbnail rounded-circle alt="
                        alt="img"
                      />
                    </div>
                    <h3 className="dark-grey-text mt-4">Tracey Poole</h3>
                    <h6 className="text-default font-weight-normal mb-3">
                      Web Designer
                    </h6>
                    <div className="text-warning mb-3">
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star-half-full"> </i>
                    </div>
                    <p className="font-weight-normal text-muted mb-0">
                      <i className="fa fa-quote-left pe-2" />
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Quod eos id officiis hic tenetur quae quaerat ad velit ab
                      hic tenetur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-xl-0">
                <div className="card-body">
                  <div className="team-section text-center">
                    <div className="team-img">
                      <img
                        src="/assets/images/users/female/1.jpg"
                        className="img-thumbnail rounded-circle alt="
                        alt="img"
                      />
                    </div>
                    <h3 className=" dark-grey-text mt-4">Harry Walker</h3>
                    <h6 className="text-default mb-3 font-weight-normal">
                      Web Developer
                    </h6>
                    <div className="text-warning mb-3">
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                    </div>
                    <p className="font-weight-normal text-muted mb-0">
                      <i className="fa fa-quote-left pe-2" />
                      Ut enim ad minima veniam, quis nostrum exercitationem
                      ullam quis nostrum corporis suscipit laboriosam, nisi ut
                      aliquid commodi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="team-section text-center">
                    <div className="team-img">
                      <img
                        src="/assets/images/users/male/2.jpg"
                        className="img-thumbnail rounded-circle alt="
                        alt="img"
                      />
                    </div>
                    <h3 className=" dark-grey-text mt-4">Paul White</h3>
                    <h6 className="text-default font-weight-normal mb-3">
                      Photographer
                    </h6>
                    <div className="text-warning mb-3">
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star"> </i>
                      <i className="fa fa-star-o"> </i>
                    </div>
                    <p className="font-weight-normal text-muted mb-0">
                      <i className="fa fa-quote-left pe-2" />
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="sptb border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-md-12">
              <div className="sub-newsletter">
                <h3 className="mb-2 fs-20">
                  <i className="fa fa-paper-plane-o me-2" /> Subscribe To Our
                  Newsletter
                </h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-xl-6 col-md-12">
              <div className="input-group sub-input mt-1">
                <input
                  type="text"
                  className="form-control input-lg "
                  placeholder="Enter your Email"
                />
                <button
                  type="button"
                  className="btn ripple  btn-primary  br-te-3  br-be-3"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
export default About;
