import React, { Children, useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Home from "../pages/home/Home";
import Footer from "./Footer";
import Navbar from "./Navbar";

function DefaultLayout({ children }) {
  const location = useLocation();
  const [layoutRequired, setLayoutRequired] = useState(true);
  const [headerRequired, setHeaderRequired] = useState(true);
  useEffect(() => {
    if (location.pathname == "/usersuggestion") {
      setLayoutRequired(false);
    } else {
      setLayoutRequired(true);
    }
    if (location.pathname == "/createpost") {
      setHeaderRequired(false);
    } else {
      setHeaderRequired(true);
    }
  }, [location.pathname]);

  return (
    <div>
      {/* <div id="global-loader">
                <img src="/assets/images/loader.svg" className="loader-img" alt="img" />
            </div>  */}
      <Navbar />
      {children}
      <Footer />
    </div>
    // <main className={"w-100 clearfix" + (headerRequired && " socialMediaTheme")}>
    //     {layoutRequired && <Sidebar />}
    //     <div className="themeContant">
    //         {layoutRequired && headerRequired && <Header />}
    //         {location.pathname == "/" &&
    //             <section className="w-100 clearfix sliderSection d-none d-md-block" id="sliderSection">
    //                 <div className="owl-carousel owl-theme graphSlider owl-loaded owl-drag" id="graphSlider">
    //                     <OwlCarousel
    //                         className="owl-stage"
    //                         margin={0}
    //                         nav={false}
    //                         dots={false}
    //                         responsive={{
    //                             0: {
    //                                 items: 1,
    //                             },
    //                             575: {
    //                                 items: 2,
    //                             },
    //                             768: {
    //                                 items: 4,
    //                             },
    //                             991: {
    //                                 items: 5,
    //                             },
    //                             1200: {
    //                                 items: 6,
    //                             }
    //                         }}
    //                     >
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>USD</h4>
    //                                         <h5>22.90</h5>
    //                                         <h6>-0.15%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="usd" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>EURUSD</h4>
    //                                         <h5>76.750</h5>
    //                                         <h6>-0.65%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="eurusd" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>OIL</h4>
    //                                         <h5>76.750</h5>
    //                                         <h6>-2.85%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="oil" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>SPX500</h4>
    //                                         <h5>156.00</h5>
    //                                         <h6>-0.65%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="spx500" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>DJ30</h4>
    //                                         <h5>29250.50</h5>
    //                                         <h6>-0.75%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="dj30" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="item">
    //                             <div className="graphSliderItem">
    //                                 <div className="graphSliderInner d-flex align-items-center">
    //                                     <div className="graphSliderTxt">
    //                                         <h4>NSDQ100</h4>
    //                                         <h5>11250.45</h5>
    //                                         <h6>+0.03%</h6>
    //                                     </div>
    //                                     <div className="sliderGraph">
    //                                         <canvas id="nsdq100" width={72} height={13} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </OwlCarousel>
    //                 </div>
    //             </section>}

    //         {children}
    //     </div >
    // </main >
  );
}
export default DefaultLayout;
